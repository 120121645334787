import React, { useState, useEffect } from 'react'
import { useTheme } from '../contexts/ThemeContext'
import { ChevronDown, ChevronUp, Check, X, Download, Image as ImageIcon } from 'lucide-react'

export default function Home({ userData, pb }) {
  const { isDarkMode } = useTheme()
  const [isLoading, setIsLoading] = useState(true)
  const [news, setNews] = useState([])
  const [permissionRequests, setPermissionRequests] = useState([])
  const [guardChangeRequests, setGuardChangeRequests] = useState([])
  const [guardChangeApprovals, setGuardChangeApprovals] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      if (!isMounted) return
      setIsLoading(true)
      setError(null)
      try {
        const [newsResult, permissionResult, guardChangeResult, guardChangeApprovalsResult] = await Promise.all([
          pb.collection('news').getList(1, 10, {
            sort: '-date',
            $autoCancel: false
          }),
          pb.collection('permission_requests').getList(1, 50, {
            filter: `approver = "${userData.id}" && status = "Pendiente"`,
            sort: 'start_date',
            $autoCancel: false
          }),
          pb.collection('permisos_cambio__guardia').getList(1, 50, {
            filter: `usuario_de_receptor = "${userData.id}" && receptor_deacuerdo = "deacuerdo"`,
            sort: 'fecha_peticion',
            $autoCancel: false
          }),
          pb.collection('permisos_cambio__guardia').getList(1, 50, {
            filter: `usuario_de_aprobador = "${userData.id}" && status = "Pendiente"`,
            sort: 'fecha_peticion',
            $autoCancel: false
          })
        ])
        if (isMounted) {
          setNews(newsResult.items)
          setPermissionRequests(permissionResult.items)
          setGuardChangeRequests(guardChangeResult.items)
          setGuardChangeApprovals(guardChangeApprovalsResult.items)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
        if (isMounted) {
          setError('Failed to fetch data. Please try again later.')
        }
      } finally {
        if (isMounted) {
          setIsLoading(false)
        }
      }
    }
    fetchData()
    return () => {
      isMounted = false
    }
  }, [pb, userData.id])

  const handleStatusChange = async (requestId, newStatus) => {
    try {
      await pb.collection('permission_requests').update(requestId, { status: newStatus })
      setPermissionRequests(prevRequests => 
        prevRequests.filter(request => request.id !== requestId)
      )
    } catch (error) {
      console.error('Error updating permission request:', error)
      setError('Failed to update permission request. Please try again.')
    }
  }

  const handleGuardChangeApproval = async (requestId, newStatus) => {
    try {
      await pb.collection('permisos_cambio__guardia').update(requestId, { status: newStatus })
      setGuardChangeApprovals(prevApprovals => 
        prevApprovals.filter(approval => approval.id !== requestId)
      )
    } catch (error) {
      console.error('Error updating guard change approval:', error)
      setError('Failed to update guard change approval. Please try again.')
    }
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    )
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>
  }

  return (
    <div className="space-y-6">
      <WelcomeBanner userData={userData} />
      <PermissionRequests requests={permissionRequests} onStatusChange={handleStatusChange} />
      <GuardChangeRequests requests={guardChangeRequests} />
      <GuardChangeApprovals approvals={guardChangeApprovals} onApproval={handleGuardChangeApproval} />
      <NewsFeed news={news} />
    </div>
  )
}

function WelcomeBanner({ userData }) {
  const { isDarkMode } = useTheme()
  return (
    <div className={`p-6 rounded-lg shadow-md ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
      <h1 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
        Bienvenido, {userData.name}!
      </h1>
      <p className={`text-lg ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
        Aquí tienes las últimas noticias y solicitudes pendientes.
      </p>
    </div>
  )
}

function PermissionRequests({ requests, onStatusChange }) {
  const { isDarkMode } = useTheme()

  if (requests.length === 0) {
    return null
  }

  return (
    <div className={`p-6 rounded-lg shadow-md ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
      <h2 className={`text-xl font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
        Solicitudes de Permisos Pendientes
      </h2>
      <div className="space-y-4">
        {requests.map((request) => (
          <div key={request.id} className={`p-4 rounded-md ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
            <p className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
              {request.expand?.requester?.name || 'Usuario Desconocido'}
            </p>
            <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Desde: {new Date(request.start_date).toLocaleDateString()} - 
              Hasta: {new Date(request.end_date).toLocaleDateString()}
            </p>
            <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Razón: {request.reason}
            </p>
            <div className="mt-2 flex space-x-2">
              <button
                onClick={() => onStatusChange(request.id, 'Aprobado')}
                className="px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors"
              >
                <Check className="h-4 w-4 inline-block mr-1" />
                Aprobar
              </button>
              <button
                onClick={() => onStatusChange(request.id, 'Rechazado')}
                className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
              >
                <X className="h-4 w-4 inline-block mr-1" />
                Rechazar
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

function GuardChangeRequests({ requests }) {
  const { isDarkMode } = useTheme()

  if (requests.length === 0) {
    return null
  }

  return (
    <div className={`p-6 rounded-lg shadow-md ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
      <h2 className={`text-xl font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
        Solicitudes de Cambio de Guardia Aceptadas
      </h2>
      <div className="space-y-4">
        {requests.map((request) => (
          <div key={request.id} className={`p-4 rounded-md ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
            <p className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
              Solicitante: {request.expand?.usuario_de_peticion?.name || 'Usuario Desconocido'}
            </p>
            <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Fecha de Petición: {new Date(request.fecha_peticion).toLocaleDateString()}
            </p>
            <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Fecha de Permiso: {new Date(request.fecha_de_permiso).toLocaleDateString()}
            </p>
            <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Estado: Aceptado por el receptor
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

function GuardChangeApprovals({ approvals, onApproval }) {
  const { isDarkMode } = useTheme()

  if (approvals.length === 0) {
    return null
  }

  return (
    <div className={`p-6 rounded-lg shadow-md ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
      <h2 className={`text-xl font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
        Aprobaciones de Cambio de Guardia Pendientes
      </h2>
      <div className="space-y-4">
        {approvals.map((approval) => (
          <div key={approval.id} className={`p-4 rounded-md ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
            <p className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
              Solicitante: {approval.expand?.usuario_de_peticion?.name || 'Usuario Desconocido'}
            </p>
            <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Receptor: {approval.expand?.usuario_de_receptor?.name || 'Usuario Desconocido'}
            </p>
            <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Fecha de Petición: {new Date(approval.fecha_peticion).toLocaleDateString()}
            </p>
            <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Fecha de Permiso: {new Date(approval.fecha_de_permiso).toLocaleDateString()}
            </p>
            <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Receptor de Acuerdo: {approval.receptor_deacuerdo === 'deacuerdo' ? 'Sí' : 'No'}
            </p>
            <div className="mt-2 flex space-x-2">
              <button
                onClick={() => onApproval(approval.id, 'Aprobado')}
                className="px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors"
              >
                <Check className="h-4 w-4 inline-block mr-1" />
                Aprobar
              </button>
              <button
                onClick={() => onApproval(approval.id, 'Rechazado')}
                className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
              >
                <X className="h-4 w-4 inline-block mr-1" />
                Rechazar
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

function NewsFeed({ news }) {
  return (
    <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      {news.map((item) => (
        <NewsCard key={item.id} newsItem={item} />
      ))}
    </div>
  )
}

function NewsCard({ newsItem }) {
  const [imageError, setImageError] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const { isDarkMode } = useTheme()

  const handleImageError = () => {
    setImageError(true)
  }

  const getFileUrl = (collectionId, recordId, fileName) => {
    return `${process.env.REACT_APP_POCKETBASE_URL}/api/files/${collectionId}/${recordId}/${fileName}`
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className={`p-6 rounded-lg shadow-md ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
      <h3 className="text-xl font-semibold mb-3">{newsItem.title}</h3>
      {newsItem.image && !imageError ? (
        <img
          src={getFileUrl('news', newsItem.id, newsItem.image)}
          alt={newsItem.title}
          className="w-full h-48 object-cover rounded-md mb-3"
          onError={handleImageError}
        />
      ) : (
        <div className={`w-full h-48 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} flex items-center justify-center rounded-md mb-3`}>
          <ImageIcon className={`h-12 w-12 ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`} />
        </div>
      )}
      <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mb-3`}>
        {new Date(newsItem.date).toLocaleDateString()} - {newsItem.author}
      </p>
      <div className={`overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-full' : 'max-h-24'} mb-3`}>
        <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>{newsItem.content}</p>
      </div>
      <div className="flex justify-between items-center">
        <button
          onClick={toggleExpand}
          className={`flex items-center text-sm font-medium ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-700'}`}
        >
          {isExpanded ? (
            <>
              <ChevronUp className="mr-1 h-4 w-4" />
              <span>Leer menos</span>
            </>
          ) : (
            <>
              <ChevronDown className="mr-1 h-4 w-4" />
              <span>Leer más</span>
            </>
          )}
        </button>
        {newsItem.pdf_attachment && (
          <a
            href={getFileUrl('news', newsItem.id, newsItem.pdf_attachment)}
            download
            className={`flex items-center text-sm font-medium ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-700'}`}
          >
            <Download className="mr-1 h-4 w-4" />
            <span>Descargar PDF</span>
          </a>
        )}
      </div>
    </div>
  )
}