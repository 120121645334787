'use client'

import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import PocketBase from 'pocketbase'
import { useUser } from '../../UserContext'

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL)
pb.autoCancellation(false)

export default function CederMes() {
  const { user } = useUser()
  const [cederMeses, setCederMeses] = useState([])

  useEffect(() => {
    if (user) {
      fetchCederMeses()
    }
  }, [user])

  const fetchCederMeses = async () => {
    try {
      const records = await pb.collection('ceder_meses').getList(1, 50, {
        filter: `approver = "${user.id}" && status = "Pendiente"`,
        sort: '-created',
        expand: 'requester,receiver',
      })
      setCederMeses(records.items)
    } catch (error) {
      console.error('Error fetching ceder meses:', error)
      alert("No se pudieron cargar las solicitudes de ceder mes.")
    }
  }

  const updateCederMesStatus = async (mesId, newStatus) => {
    try {
      await pb.collection('ceder_meses').update(mesId, { status: newStatus })
      fetchCederMeses()
      alert(`La solicitud de ceder mes ha sido ${newStatus.toLowerCase()}.`)
    } catch (error) {
      console.error('Error updating ceder mes status:', error)
      alert("No se pudo actualizar el estado de la solicitud de ceder mes.")
    }
  }

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'MMMM yyyy', { locale: es })
  }

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Solicitudes de Ceder Mes</h2>
      <div className="space-y-4">
        {cederMeses.map((mes) => (
          <div key={mes.id} className="bg-white rounded-lg shadow-md p-6 transition duration-300 ease-in-out hover:shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <span className="font-semibold text-gray-800">
                {mes.expand?.requester?.name} → {mes.expand?.receiver?.name}
              </span>
              <span className="text-sm text-gray-600">
                Mes: {formatDate(mes.month)}
              </span>
            </div>
            <p className="text-gray-800 mb-4">{mes.reason}</p>
            <div className="flex justify-end space-x-2">
              <button 
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300 ease-in-out"
                onClick={() => updateCederMesStatus(mes.id, 'Aprobado')}
              >
                Aprobar
              </button>
              <button 
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300 ease-in-out"
                onClick={() => updateCederMesStatus(mes.id, 'Rechazado')}
              >
                Rechazar
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}