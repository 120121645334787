import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PocketBase from 'pocketbase';
import { useTheme } from '../contexts/ThemeContext';
import { Calendar as CalendarIcon, User, UserCheck, Loader, Building, Check } from 'lucide-react';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

export default function CambioGuardias({ userData }) {
  const { control, handleSubmit, formState: { errors }, reset, watch } = useForm();
  const [users, setUsers] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const { isDarkMode } = useTheme();

  const watchDivisionPeticion = watch('division_peticion');
  const watchDivisionReceptor = watch('division_receptor');

  const fetchData = useCallback(async () => {
    try {
      const usersResponse = await pb.collection('users').getList(1, 1000, {
        sort: 'name',
        $autoCancel: false
      });
      const divisionsResponse = await pb.collection('divisions').getList(1, 1000, {
        sort: 'name',
        $autoCancel: false
      });
      setUsers(usersResponse.items);
      const filteredDivisions = divisionsResponse.items.filter(division =>
        !['gestion de calidad', 'laboratorio', 'hormonas'].includes(division.name.toLowerCase())
      );
      setDivisions(filteredDivisions);
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessage('Error al cargar los datos. Por favor, intente de nuevo más tarde.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const addHours = (date, hours) => {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + hours);
    return newDate;
  };

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setMessage('');
    try {
      const currentDate = new Date();
      const fechaDePermiso = addHours(currentDate, 7);
      const fechaPeticion = addHours(new Date(data.fecha_peticion), 7);
      const fechaReceptor = addHours(new Date(data.fecha_receptor), 7);

      const cambioGuardiaData = {
        usuario_de_peticion: userData.id,
        division_peticion: data.division_peticion,
        usuario_de_receptor: data.usuario_de_receptor,
        division_receptor: data.division_receptor,
        usuario_de_aprobador: data.usuario_de_aprobador,
        fecha_de_permiso: fechaDePermiso.toISOString(),
        fecha_peticion: fechaPeticion.toISOString(),
        fecha_receptor: fechaReceptor.toISOString(),
        status: 'Pendiente',
      };

      await pb.collection('permisos_cambio__guardia').create(cambioGuardiaData);
      setMessage('Solicitud de cambio de guardia enviada con éxito');
      reset();
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
      setMessage('Error al enviar la solicitud. Por favor, inténtelo de nuevo.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin h-12 w-12 text-blue-500" />
      </div>
    );
  }

  return (
    <div className={`max-w-4xl mx-auto p-4 sm:p-6 md:p-8 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} shadow-lg rounded-lg transition-all duration-300`}>
      <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center">Solicitud de Cambio de Guardia</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-6">
            <FormField id="division_peticion" label="Mi Guardia A Regalar " icon={<Building />} error={errors.division_peticion} isDarkMode={isDarkMode}>
              <Controller
                name="division_peticion"
                control={control}
                rules={{ required: "Este campo es requerido" }}
                render={({ field }) => (
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                    {divisions.map(division => (
                      <button
                        key={division.id}
                        type="button"
                        onClick={() => {
                          if (field.value === division.id) {
                            field.onChange('');
                          } else {
                            field.onChange(division.id);
                          }
                        }}
                        className={`p-3 rounded-md flex justify-between items-center
                          ${field.value === division.id
                            ? (isDarkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white')
                            : (isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-200 text-gray-700')
                          } hover:bg-blue-400 transition-colors`}
                      >
                        <span className="flex-grow text-left">{division.name}</span>
                        {field.value === division.id && <Check className="h-4 w-4 ml-2 flex-shrink-0" />}
                      </button>
                    ))}
                  </div>
                )}
              />
            </FormField>
            <FormField id="fecha_peticion" label="Fecha de mi Guardia" icon={<CalendarIcon />} error={errors.fecha_peticion} isDarkMode={isDarkMode}>
              <Controller
                name="fecha_peticion"
                control={control}
                rules={{ required: "Este campo es requerido" }}
                render={({ field }) => (
                  <input
                    type="date"
                    {...field}
                    className={inputClassName(isDarkMode)}
                    min={formatDate(new Date())}
                  />
                )}
              />
            </FormField>
          </div>
          <div className="space-y-6">
            <FormField id="division_receptor" label="La Guardia Propuesta (Del Receptor)" icon={<Building />} error={errors.division_receptor} isDarkMode={isDarkMode}>
              <Controller
                name="division_receptor"
                control={control}
                rules={{ required: "Este campo es requerido" }}
                render={({ field }) => (
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                    {divisions.map(division => (
                      <button
                        key={division.id}
                        type="button"
                        onClick={() => {
                          if (field.value === division.id) {
                            field.onChange('');
                          } else {
                            field.onChange(division.id);
                          }
                        }}
                        className={`p-3 rounded-md flex justify-between items-center
                          ${field.value === division.id
                            ? (isDarkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white')
                            : (isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-200 text-gray-700')
                          } hover:bg-blue-400 transition-colors`}
                      >
                        <span className="flex-grow text-left">{division.name}</span>
                        {field.value === division.id && <Check className="h-4 w-4 ml-2 flex-shrink-0" />}
                      </button>
                    ))}
                  </div>
                )}
              />
            </FormField>
            <FormField id="fecha_receptor" label="Fecha de la Guardia del Receptor" icon={<CalendarIcon />} error={errors.fecha_receptor} isDarkMode={isDarkMode}>
              <Controller
                name="fecha_receptor"
                control={control}
                rules={{ required: "Este campo es requerido" }}
                render={({ field }) => (
                  <input
                    type="date"
                    {...field}
                    className={inputClassName(isDarkMode)}
                    min={formatDate(new Date())}
                  />
                )}
              />
            </FormField>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FormField id="usuario_de_receptor" label="Usuario Con quien voy a cambiar guardia (Receptor)" icon={<User />} error={errors.usuario_de_receptor} isDarkMode={isDarkMode}>
            <Controller
              name="usuario_de_receptor"
              control={control}
              rules={{ required: "Este campo es requerido" }}
              render={({ field }) => (
                <select {...field} className={inputClassName(isDarkMode)}>
                  <option value="">Seleccione un usuario</option>
                  {users.map(user => (
                    <option key={user.id} value={user.id}>{user.name}</option>
                  ))}
                </select>
              )}
            />
          </FormField>
          <FormField id="usuario_de_aprobador" label="Jefatura o Encargado de la Guardia" icon={<UserCheck />} error={errors.usuario_de_aprobador} isDarkMode={isDarkMode}>
            <Controller
              name="usuario_de_aprobador"
              control={control}
              rules={{ required: "Este campo es requerido" }}
              render={({ field }) => (
                <select {...field} className={inputClassName(isDarkMode)}>
                  <option value="">Seleccione una jefatura</option>
                  {users.filter(user => user.role === 'jefatura' || user.is_approver).map(approver => (
                    <option key={approver.id} value={approver.id}>
                      {approver.name}
                      {approver.role ? ` - ${approver.role}` : ''}
                      {approver.is_approver ? ' - Aprobador' : ''}
                    </option>
                  ))}
                </select>
              )}
            />
          </FormField>
        </div>
        <div className="flex justify-center mt-8">
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full md:w-auto px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white
              ${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-600 hover:bg-blue-700'}
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out`}
          >
            {isSubmitting ? 'Enviando...' : 'Enviar Solicitud'}
          </button>
        </div>
      </form>
      {message && (
        <div className={`mt-6 p-4 rounded-md ${message.includes('Error') ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
          {message}
        </div>
      )}
    </div>
  );
}

function FormField({ id, label, icon, error, children, isDarkMode }) {
  return (
    <div className="mb-4">
      <label htmlFor={id} className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} mb-2`}>
        {label}
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          {React.cloneElement(icon, { className: `h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}` })}
        </div>
        <div className="pl-10">
          {children}
        </div>
      </div>
      {error && <span className="text-red-500 text-xs mt-1">{error.message}</span>}
    </div>
  );
}

function inputClassName(isDarkMode) {
  return `block w-full pl-10 pr-3 py-2 sm:text-sm border-gray-300 rounded-md
    ${isDarkMode
      ? 'bg-gray-700 text-white focus:ring-blue-500 focus:border-blue-500'
      : 'bg-white text-gray-900 focus:ring-blue-500 focus:border-blue-500'
    }`;
}