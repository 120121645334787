'use client'

import React, { useState, useEffect } from 'react'
import PocketBase from 'pocketbase'
import { useUser } from '../UserContext'
import { useTheme } from '../contexts/ThemeContext'
import { User, Mail, Phone, Building, Briefcase, Shield, CheckSquare, Loader2, Edit2, Save, X, Camera, Calendar, MapPin, Info } from 'lucide-react'

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL)

export default function PerfilUsuario() {
  const { user } = useUser()
  const { isDarkMode } = useTheme()
  const [userDetails, setUserDetails] = useState(null)
  const [vacations, setVacations] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [editedDetails, setEditedDetails] = useState(null)
  const [activeTab, setActiveTab] = useState('info')
  const [showPhoneTooltip, setShowPhoneTooltip] = useState(false)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const fetchUserDetailsAndVacations = async () => {
      setIsLoading(true)
      setError(null)

      try {
        await pb.admins.authWithPassword(
          process.env.REACT_APP_ADMIN_EMAIL,
          process.env.REACT_APP_ADMIN_PASSWORD,
          { signal: controller.signal }
        )

        if (user && isMounted) {
          const details = await pb.collection('users').getOne(user.id, {
            expand: 'division',
            signal: controller.signal
          })

          const vacationRecords = await pb.collection('vacations').getList(1, 50, {
            filter: `user = "${user.id}"`,
            sort: '-start_date',
            signal: controller.signal
          })

          if (isMounted) {
            setUserDetails(details)
            setEditedDetails(details)
            setVacations(vacationRecords.items)
          }
        }
      } catch (err) {
        if (err.name !== 'AbortError' && isMounted) {
          console.error('Error fetching user details and vacations:', err)
          setError('Error al cargar los datos del usuario. Por favor, intente de nuevo más tarde.')
        }
      } finally {
        if (isMounted) {
          setIsLoading(false)
        }
      }
    }

    fetchUserDetailsAndVacations()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [user])

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleCancel = () => {
    setIsEditing(false)
    setEditedDetails(userDetails)
  }

  const handleSave = async () => {
    try {
      const editableFields = userInfo.filter(item => item.editable).map(item => item.value)
      const updatedData = Object.fromEntries(
        Object.entries(editedDetails).filter(([key]) => editableFields.includes(key))
      )
      const updatedUser = await pb.collection('users').update(user.id, updatedData)
      setUserDetails(updatedUser)
      setIsEditing(false)
    } catch (err) {
      console.error('Error updating user details:', err)
      setError('Error al actualizar los datos del usuario. Por favor, intente de nuevo.')
    }
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target
    setEditedDetails(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }))
  }

  const handleAvatarChange = async (e) => {
    const file = e.target.files[0]
    if (file) {
      try {
        const formData = new FormData()
        formData.append('avatar', file)
        const updatedUser = await pb.collection('users').update(user.id, formData)
        setUserDetails(updatedUser)
        setEditedDetails(updatedUser)
      } catch (err) {
        console.error('Error updating avatar:', err)
        setError('Error al actualizar el avatar. Por favor, intente de nuevo.')
      }
    }
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100 dark:bg-gray-900">
        <Loader2 className="h-12 w-12 animate-spin text-blue-500" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100 dark:bg-gray-900">
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-red-600 dark:text-red-400 mb-4">Error</h2>
          <p className="text-gray-700 dark:text-gray-300">{error}</p>
        </div>
      </div>
    )
  }

  if (!userDetails) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100 dark:bg-gray-900">
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200 mb-4">No se encontró información del usuario</h2>
        </div>
      </div>
    )
  }

  const userInfo = [
    { icon: User, label: 'Nombre', value: 'name', editable: true },
    { icon: Mail, label: 'Email', value: 'email', editable: true },
    { icon: Phone, label: 'Teléfono', value: 'telefono', editable: true },
    { icon: Building, label: 'División', value: 'division', isSelect: true, options: ['División 1', 'División 2', 'División 3'], editable: false },
    { icon: MapPin, label: 'Ubicación', value: 'ubicacion', editable: true },
    { icon: Shield, label: 'Rol', value: 'role', isSelect: true, options: ['jefatura', 'subjefatura', 'regular'], editable: false },
  ]

  const workAreas = [
    { key: 'quimica', label: 'Química Clínica' },
    { key: 'hematologia', label: 'Hematología' },
    { key: 'bacterias', label: 'Microbiología' },
    { key: 'biologia', label: 'Biología Molecular' },
    { key: 'preana', label: 'Preanalítico' },
    { key: 'hormonas', label: 'Hormonas y Marcadores' },
    { key: 'inmunologia', label: 'Inmunología' },
    { key: 'banco', label: 'Banco de Sangre' },
    { key: 'emergencias', label: 'Laboratorio de Emergencias' },
    { key: 'gestion', label: 'Gestión de Calidad' }
  ]

  return (
    <div className={`min-h-screen w-full p-4 sm:p-6 lg:p-8 bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 font-outfit`}>
      <div className="max-w-4xl mx-auto">
        <div className="bg-white dark:bg-gray-800 shadow-xl rounded-2xl overflow-hidden">
          <div className="p-6 sm:p-8 lg:p-10 flex flex-col sm:flex-row items-center sm:items-start space-y-4 sm:space-y-0 sm:space-x-6">
            <div className="relative">
              {userDetails.avatar ? (
                <img src={pb.getFileUrl(userDetails, userDetails.avatar)} alt="Avatar del usuario" className="w-32 h-32 rounded-full object-cover border-4 border-blue-500 shadow-lg" />
              ) : (
                <div className="w-32 h-32 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center border-4 border-blue-500 shadow-lg">
                  <User className="w-16 h-16 text-blue-500" />
                </div>
              )}
              {isEditing && (
                <label htmlFor="avatar-upload" className="absolute bottom-0 right-0 bg-blue-500 rounded-full p-2 cursor-pointer shadow-md hover:bg-blue-600 transition-colors duration-200">
                  <Camera className="w-5 h-5 text-white" />
                  <input id="avatar-upload" type="file" accept="image/*" className="hidden" onChange={handleAvatarChange} />
                </label>
              )}
            </div>
            <div className="text-center sm:text-left">
              <h2 className="text-3xl sm:text-4xl font-bold mb-2">{editedDetails.name}</h2>
              <p className="text-xl text-gray-600 dark:text-gray-400">{editedDetails.role || 'No asignado'}</p>
              {!isEditing && (
                <button
                  onClick={handleEdit}
                  className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center sm:justify-start"
                >
                  <Edit2 className="w-4 h-4 mr-2" />
                  Editar Perfil
                </button>
              )}
            </div>
          </div>
          <div className="border-b border-gray-200 dark:border-gray-700">
            <nav className="flex justify-center sm:justify-start px-6 sm:px-8 lg:px-10">
              {['info', 'areas', 'vacations'].map((tab) => (
                <button
                  key={tab}
                  className={`px-4 py-3 text-sm font-medium transition-colors duration-200 ${
                    activeTab === tab
                      ? 'border-b-2 border-blue-500 text-blue-500'
                      : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab === 'info' && 'Información Personal'}
                  {tab === 'areas' && 'Áreas de Trabajo'}
                  {tab === 'vacations' && 'Vacaciones'}
                </button>
              ))}
            </nav>
          </div>
          <div className="p-6 sm:p-8 lg:p-10">
            {activeTab === 'info' && (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {userInfo.map((item) => (
                  <div key={item.value} className="flex items-center space-x-4 bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
                    <item.icon className="w-6 h-6 text-blue-500" />
                    <div className="flex-grow">
                      {isEditing && item.editable ? (
                        item.isSelect ? (
                          <select
                            name={item.value}
                            value={editedDetails[item.value]}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded-md bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                          >
                            {item.options.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            type="text"
                            name={item.value}
                            value={editedDetails[item.value] || ''}
                            onChange={handleInputChange}
                            placeholder={item.label}
                            className="w-full p-2 border rounded-md bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                          />
                        )
                      ) : (
                        <div>
                          <p className="text-sm text-gray-500 dark:text-gray-400">{item.label}</p>
                          <p className="font-medium">{editedDetails[item.value] || 'No disponible'}</p>
                        </div>
                      )}
                    </div>
                    {item.value === 'telefono' && (
                      <div className="relative">
                        <button
                          onMouseEnter={() => setShowPhoneTooltip(true)}
                          onMouseLeave={() => setShowPhoneTooltip(false)}
                          className="text-blue-500 hover:text-blue-600"
                        >
                          <Info className="w-5 h-5" />
                        </button>
                        {showPhoneTooltip && (
                          <div className="absolute right-0 mt-2 p-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg z-10 w-64">
                            <p className="text-sm text-gray-600 dark:text-gray-300">
                              Este apartado va a funcionar para enviar solicitudes a los usuarios como notificaciones de cambios de guardia, estados de sus peticiones y demás peticiones que se van a ir agregando progresivamente.
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {activeTab === 'areas' && (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {workAreas.map((area) => (
                  <div key={area.key} className="flex items-center space-x-2 bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
                    <input
                      type="checkbox"
                      id={area.key}
                      name={area.key}
                      checked={editedDetails[area.key]}
                      disabled={true}
                      className="rounded text-blue-500 focus:ring-blue-500"
                    />
                    <label htmlFor={area.key} className="text-sm">{area.label}</label>
                  </div>
                ))}
              </div>
            )}
            {activeTab === 'vacations' && (
              <div className="space-y-4">
                {vacations.length > 0 ? (
                  vacations.map((vacation) => (
                    <div key={vacation.id} className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg shadow">
                      <div className="flex items-center space-x-2 mb-2">
                        <Calendar className="w-5 h-5 text-blue-500" />
                        <span className="font-medium">
                          {new Date(vacation.start_date).toLocaleDateString()} - {new Date(vacation.end_date).toLocaleDateString()}
                        </span>
                      </div>
                      <p className="text-sm">
                        <strong>Tipo:</strong> {vacation.type === 'ordinary' ? 'Ordinarias' : 'Profilácticas'}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="text-center text-gray-500 dark:text-gray-400">No hay periodos de vacaciones registrados.</p>
                )}
              </div>
            )}
          </div>
          {isEditing && (
            <div className="px-6 sm:px-8 lg:px-10 py-4 bg-gray-50 dark:bg-gray-700 flex justify-end space-x-2">
              <button
                onClick={handleCancel}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 dark:text-gray-300 dark:bg-gray-600 dark:border-gray-500 dark:hover:bg-gray-500 transition-colors duration-200"
              >
                <X className="w-4 h-4 mr-2 inline" />
                Cancelar
              </button>
              <button
                onClick={handleSave}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-colors duration-200"
              >
                <Save className="w-4 h-4 mr-2 inline" />
                Guardar
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}