import React, { useState, useEffect } from 'react';
import { Eye, EyeOff, Loader2 } from 'lucide-react';
import PocketBase from 'pocketbase';
import { useTheme } from '../contexts/ThemeContext';
import { useUser } from '../UserContext';
import darkLogo from '../logos/logo dark 1.png';
import lightLogo from '../logos/logo.png';
import { Link } from 'react-router-dom';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);
pb.autoCancellation(false)

export default function Login({ onLogin }) {
  const { isDarkMode } = useTheme();
  const { setUser } = useUser();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [imageError, setImageError] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoggingInWithGoogle, setIsLoggingInWithGoogle] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const isValid = await pb.authStore.isValid;
        if (isValid) {
          const userData = await pb.collection('users').getOne(pb.authStore.model.id);
          setUser(userData);
          onLogin(userData);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      } finally {
        setIsCheckingAuth(false);
      }
    };
    checkAuth();
  }, [setUser, onLogin]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoggingIn(true);
    try {
      const authData = await pb.collection('users').authWithPassword(username, password);
      if (authData.record) {
        const userData = await pb.collection('users').getOne(authData.record.id);
        setUser(userData);
        onLogin(userData);
      }
    } catch (error) {
      console.error('Login failed:', error);
      if (error.data?.data?.provider === 'google') {
        setError('Este usuario se registró con Google. Por favor, use el botón "Iniciar sesión con Google".');
      } else {
        setError('Nombre de usuario o contraseña inválidos. Por favor, intente de nuevo.');
      }
    } finally {
      setIsLoggingIn(false);
    }
  };

  const handleGoogleLogin = async () => {
    setError('');
    setIsLoggingInWithGoogle(true);
    try {
      const authData = await pb.collection('users').authWithOAuth2({
        provider: 'google',
        redirectUrl: 'https://portal-capacitaciones.vercel.app/auth/callback',
      });

      if (authData && authData.record) {
        const userData = await pb.collection('users').getOne(authData.record.id);
        setUser(userData);
        onLogin(userData);
      } else {
        throw new Error('No se recibió información del usuario desde Google');
      }
    } catch (error) {
      console.error('Error en inicio de sesión con Google:', error);
      setError('Error al iniciar sesión con Google. Por favor, intente de nuevo.');
    } finally {
      setIsLoggingInWithGoogle(false);
    }
  };

  const handleImageError = () => {
    setImageError(true);
  };

  if (isCheckingAuth) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
        <Loader2 className="w-12 h-12 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className={`min-h-screen w-full flex items-center justify-center p-4 transition-colors duration-300 ${
      isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'
    }`}>
      <div className={`w-full max-w-md bg-white dark:bg-gray-800 rounded-2xl shadow-xl overflow-hidden transition-all duration-300 transform hover:scale-105`}>
        <div className="p-8">
          <div className="flex flex-col items-center mb-8">
            <div className="w-32 h-32 relative mb-4">
              <img
                src={isDarkMode ? darkLogo : lightLogo}
                alt="Logo HSJD"
                className="w-full h-full object-contain transition-opacity duration-300"
                onError={handleImageError}
              />
              {imageError && <div className="absolute inset-0 flex items-center justify-center text-gray-500">Logo no disponible</div>}
            </div>
            <h1 className="text-3xl font-bold text-center mb-2">Portal de Personal</h1>
            <h2 className="text-xl font-outfit text-center text-blue-600 dark:text-blue-400">Hospital San Juan de Dios</h2>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-2">
              <label htmlFor="username" className="block text-sm font-medium">Nombre de usuario</label>
              <input
                id="username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Ingrese su nombre de usuario"
                required
                className={`w-full px-4 py-3 rounded-lg border ${
                  isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'
                } focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300`}
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="password" className="block text-sm font-medium">Contraseña</label>
              <div className="relative">
                <input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Ingrese su contraseña"
                  required
                  className={`w-full px-4 py-3 rounded-lg border ${
                    isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'
                  } focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300`}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 transition-colors duration-300"
                >
                  {showPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                </button>
              </div>
            </div>
            <div className="text-sm">
              <Link to="/password-recovery" className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 transition-colors duration-300">
                ¿Olvidaste tu contraseña?
              </Link>
            </div>
            {error && <p className="text-red-500 text-sm text-center">{error}</p>}
            <button
              type="submit"
              disabled={isLoggingIn}
              className={`w-full py-3 px-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 ${
                isLoggingIn ? 'opacity-75 cursor-not-allowed' : ''
              }`}
            >
              {isLoggingIn ? (
                <Loader2 className="w-5 h-5 animate-spin mx-auto" />
              ) : (
                'Iniciar sesión'
              )}
            </button>
          </form>
          <div className="mt-8">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300 dark:border-gray-700"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className={`px-2 ${isDarkMode ? 'bg-gray-800 text-gray-400' : 'bg-white text-gray-500'}`}>
                  O continúe con
                </span>
              </div>
            </div>
            <div className="mt-6">
              <button
                onClick={handleGoogleLogin}
                disabled={isLoggingInWithGoogle}
                className={`w-full py-3 px-4 bg-white dark:bg-gray-700 text-gray-800 dark:text-white font-semibold rounded-lg border border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-all duration-300 flex items-center justify-center ${
                  isLoggingInWithGoogle ? 'opacity-75 cursor-not-allowed' : ''
                }`}
              >
                {isLoggingInWithGoogle ? (
                  <Loader2 className="w-5 h-5 animate-spin mx-auto" />
                ) : (
                  <>
                    <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                      <path
                        fill="#4285F4"
                        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                      />
                      <path
                        fill="#34A853"
                        d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                      />
                      <path
                        fill="#FBBC05"
                        d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                      />
                      <path
                        fill="#EA4335"
                        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                      />
                      <path fill="none" d="M1 1h22v22H1z" />
                    </svg>
                    Iniciar sesión con Google
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}