'use client'

import React, { useState, useEffect } from 'react'
import PocketBase from 'pocketbase'
import { useUser } from '../../src/UserContext'  // Import the useUser hook

const pb = new PocketBase(process.env.NEXT_PUBLIC_POCKETBASE_URL)

export default function SocoCreator() {
  const { user } = useUser()  // Use the useUser hook to get the current user
  const [formData, setFormData] = useState({
    descripcion: '',
    servicio: '',
    validadoPor: '',
    activo: true,
  })
  const [divisions, setDivisions] = useState([])
  const [administrators, setAdministrators] = useState([])
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const divisionsData = await pb.collection('divisions').getFullList()
        const administratorsData = await pb.collection('UsuarioAdministrador').getFullList()
        setDivisions(divisionsData)
        setAdministrators(administratorsData)
      } catch (e) {
        console.error('Error fetching collections:', e)
        setError('Failed to load necessary data. Please try again.')
      } finally {
        setIsLoading(false)
      }
    }

    fetchCollections()
  }, [])

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(null)
    setSuccess(false)

    if (!user) {
      setError('User information is missing. Please log in again.')
      return
    }

    try {
      const data = {
        ...formData,
        user: user.id,  // Use the user.id from the context
      }

      await pb.collection('soco').create(data)
      setSuccess(true)
      setFormData({
        descripcion: '',
        servicio: '',
        validadoPor: '',
        activo: true,
      })
    } catch (e) {
      console.error('Error creating soco:', e)
      setError('Failed to create soco: ' + e.message)
    }
  }

  if (!user) {
    return <div className="text-red-500">Please log in to create a soco entry.</div>
  }

  if (isLoading) {
    return <div className="text-center">Loading...</div>
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Create Soco Entry</h1>
      
      {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">{error}</div>}
      {success && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">Soco entry created successfully!</div>}
      
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="descripcion">
            Descripción
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="descripcion"
            name="descripcion"
            value={formData.descripcion}
            onChange={handleInputChange}
            required
          />
        </div>
        
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="servicio">
            Servicio
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="servicio"
            name="servicio"
            value={formData.servicio}
            onChange={handleInputChange}
            required
          >
            <option value="">Select a service</option>
            {divisions.map(division => (
              <option key={division.id} value={division.id}>{division.name}</option>
            ))}
          </select>
        </div>
        
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="validadoPor">
            Validado Por
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="validadoPor"
            name="validadoPor"
            value={formData.validadoPor}
            onChange={handleInputChange}
            required
          >
            <option value="">Select a validator</option>
            {administrators.map(admin => (
              <option key={admin.id} value={admin.id}>{admin.name}</option>
            ))}
          </select>
        </div>
        
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="activo"
              checked={formData.activo}
              onChange={handleInputChange}
              className="mr-2 leading-tight"
            />
            <span className="text-sm">Activo</span>
          </label>
        </div>
        
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Create Soco Entry
        </button>
      </form>
    </div>
  )
}