'use client'

import React, { useState } from 'react'
import { useTheme } from '../contexts/ThemeContext'
import { Bell, Lock, Eye, EyeOff, Moon, Sun } from 'lucide-react'
import PocketBase from 'pocketbase'

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL)

export default function Configuracion({ userData, updateUserData }) {
  const { isDarkMode, toggleTheme } = useTheme()
  const [notifications, setNotifications] = useState({
    email: userData.notifications?.email ?? true,
    push: userData.notifications?.push ?? true,
    sms: userData.notifications?.sms ?? false,
  })
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [passwordSuccess, setPasswordSuccess] = useState('')

  const handleNotificationChange = async (type) => {
    const updatedNotifications = { ...notifications, [type]: !notifications[type] }
    setNotifications(updatedNotifications)
    
    try {
      await pb.collection('users').update(userData.id, { notifications: updatedNotifications })
      updateUserData({ ...userData, notifications: updatedNotifications })
    } catch (error) {
      console.error('Error updating notifications:', error)
      // Revert the change if the update fails
      setNotifications(notifications)
    }
  }

  const handlePasswordSubmit = async (e) => {
    e.preventDefault()
    setPasswordError('')
    setPasswordSuccess('')

    if (newPassword !== confirmPassword) {
      setPasswordError('Las contraseñas nuevas no coinciden')
      return
    }

    try {
      // Authenticate the user with their current password
      await pb.collection('users').authWithPassword(userData.email, currentPassword)

      // Update the password
      await pb.collection('users').update(userData.id, {
        password: newPassword,
        passwordConfirm: confirmPassword,
      })

      setPasswordSuccess('Contraseña actualizada con éxito')
      setCurrentPassword('')
      setNewPassword('')
      setConfirmPassword('')
    } catch (error) {
      console.error('Error updating password:', error)
      setPasswordError('Error al actualizar la contraseña. Asegúrate de que la contraseña actual sea correcta.')
    }
  }

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-8">
      <h1 className="text-2xl font-bold mb-4">Configuración</h1>

      {/* Tema */}
      <section>
        <h2 className="text-xl font-semibold mb-2">Tema</h2>
        <div className="flex items-center space-x-4">
          <button
            onClick={toggleTheme}
            className={`p-2 rounded-md transition-colors duration-200 ${
              isDarkMode
                ? 'bg-gray-700 text-yellow-300'
                : 'bg-gray-200 text-gray-600'
            }`}
          >
            {isDarkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
          </button>
          <span>{isDarkMode ? 'Modo oscuro' : 'Modo claro'}</span>
        </div>
      </section>

      {/* Notificaciones */}
      <section>
        <h2 className="text-xl font-semibold mb-2">Notificaciones</h2>
        <div className="space-y-2">
          {Object.entries(notifications).map(([type, enabled]) => (
            <div key={type} className="flex items-center space-x-4">
              <Bell className="h-5 w-5" />
              <span className="capitalize">{type}</span>
              <button
                onClick={() => handleNotificationChange(type)}
                className={`w-12 h-6 rounded-full p-1 transition-colors duration-200 ${
                  enabled ? 'bg-green-500' : 'bg-gray-300'
                }`}
              >
                <div
                  className={`w-4 h-4 rounded-full bg-white transition-transform duration-200 ${
                    enabled ? 'transform translate-x-6' : ''
                  }`}
                />
              </button>
            </div>
          ))}
        </div>
      </section>

      {/* Cambiar contraseña */}
      <section>
        <h2 className="text-xl font-semibold mb-2">Cambiar contraseña</h2>
        <form onSubmit={handlePasswordSubmit} className="space-y-4">
          <div className="flex items-center space-x-4">
            <Lock className="h-5 w-5" />
            <div className="relative flex-grow">
              <input
                type={showCurrentPassword ? 'text' : 'password'}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Contraseña actual"
                className="w-full p-2 pr-10 rounded-md bg-gray-100 dark:bg-gray-700"
                required
              />
              <button
                type="button"
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
              >
                {showCurrentPassword ? (
                  <EyeOff className="h-5 w-5 text-gray-400" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <Lock className="h-5 w-5" />
            <div className="relative flex-grow">
              <input
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Nueva contraseña"
                className="w-full p-2 pr-10 rounded-md bg-gray-100 dark:bg-gray-700"
                required
              />
              <button
                type="button"
                onClick={() => setShowNewPassword(!showNewPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
              >
                {showNewPassword ? (
                  <EyeOff className="h-5 w-5 text-gray-400" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <Lock className="h-5 w-5" />
            <div className="relative flex-grow">
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirmar nueva contraseña"
                className="w-full p-2 pr-10 rounded-md bg-gray-100 dark:bg-gray-700"
                required
              />
            </div>
          </div>
          {passwordError && <p className="text-red-500">{passwordError}</p>}
          {passwordSuccess && <p className="text-green-500">{passwordSuccess}</p>}
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200"
          >
            Cambiar contraseña
          </button>
        </form>
      </section>
    </div>
  )
}