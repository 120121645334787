import React, { useState } from 'react'
import { Inbox, Calendar, UserPlus, CalendarDays, Sun, Moon } from 'lucide-react'
import PermissionRequests from '../ApproverInbox/Permisosingose'
import RegularGuardia from '../ApproverInbox/regalarGuardia'
import CederGuardia from '../ApproverInbox/cederGuardia'
import CederMes from '../ApproverInbox/cederMes'
import { useTheme } from '../../contexts/ThemeContext'

export default function ApproverInbox() {
  const [activeTab, setActiveTab] = useState('permisos')
  const { isDarkMode, toggleTheme } = useTheme()

  const tabs = [
    { id: 'permisos', label: 'Permisos', icon: Inbox },
    { id: 'regularGuardia', label: 'Regular Guardia', icon: Calendar },
    { id: 'cederGuardia', label: 'Ceder Guardia', icon: UserPlus },
    { id: 'cederMes', label: 'Ceder Mes', icon: CalendarDays },
  ]

  const renderActiveComponent = () => {
    switch (activeTab) {
      case 'permisos':
        return <PermissionRequests />
      case 'regularGuardia':
        return <RegularGuardia />
      case 'cederGuardia':
        return <CederGuardia />
      case 'cederMes':
        return <CederMes />
      default:
        return <PermissionRequests />
    }
  }

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <nav className="flex flex-wrap items-center border-b border-gray-200 dark:border-gray-700">
          <div className="flex flex-wrap flex-1">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`flex items-center justify-center px-3 py-2 text-xs sm:text-sm font-medium transition-colors duration-200 ease-in-out focus:outline-none ${
                  activeTab === tab.id
                    ? isDarkMode
                      ? 'bg-blue-500 text-blue-200 border-b-2 border-blue-200'
                      : 'bg-blue-50 text-blue-700 border-b-2 border-blue-500'
                    : isDarkMode
                    ? 'text-gray-300 hover:text-gray-100 hover:bg-gray-700'
                    : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                }`}
              >
                <tab.icon className="h-4 w-4 mr-1 sm:mr-2" aria-hidden="true" />
                <span className="hidden sm:inline">{tab.label}</span>
              </button>
            ))}
          </div>
        </nav>
        <div className="w-full">
          {renderActiveComponent()}
        </div>
      </div>
    </div>
  )
}