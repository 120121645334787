import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import { useUser } from '../UserContext';
import { useTheme } from '../contexts/ThemeContext';
import { FileText, Loader, AlertCircle, CheckCircle } from 'lucide-react';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

export default function AgregarTicket() {
  const { user } = useUser();
  const { isDarkMode } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [peticion, setPeticion] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    try {
      if (!user) {
        throw new Error('Usuario no autenticado');
      }

      const data = {
        usuario: user.id,
        peticion: peticion.trim(),
        status: 'Pendiente',
        dia_peticion: new Date().toISOString().split('T')[0],
      };

      console.log('Datos a enviar:', data);

      const createdTicket = await pb.collection('tickets').create(data);
      console.log('Ticket creado:', createdTicket);

      setSuccess(true);
      setPeticion('');
    } catch (error) {
      console.error('Error al crear el ticket:', error);
      if (error.data) {
        console.error('Detalles del error:', error.data);
      }
      setError(`Error al crear el ticket: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (!user) {
    return (
      <div className="mt-4 p-3 bg-red-100 text-red-700 rounded flex items-center">
        <AlertCircle className="mr-2" />
        Usuario no autenticado. Por favor, inicie sesión.
      </div>
    );
  }

  return (
    <div className={`max-w-2xl mx-auto mt-10 p-6 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg shadow-xl`}>
      <h2 className="text-2xl font-bold mb-6 flex items-center">
        <FileText className="mr-2" />
        Crear Nuevo Ticket
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="peticion" className="block text-sm font-medium mb-1">
            <FileText className="inline mr-2" />
            Petición
          </label>
          <textarea
            id="peticion"
            value={peticion}
            onChange={(e) => setPeticion(e.target.value)}
            required
            className={`mt-1 block w-full px-3 py-2 text-base border ${
              isDarkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'
            } rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
            rows="4"
            placeholder="Describa su petición aquí"
          ></textarea>
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isLoading || peticion.trim().length === 0}
            className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
              isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
              (isLoading || peticion.trim().length === 0) ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isLoading ? (
              <>
                <Loader className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                Enviando...
              </>
            ) : (
              'Crear Ticket'
            )}
          </button>
        </div>
      </form>
      {error && (
        <div className="mt-4 p-3 bg-red-100 text-red-700 rounded flex items-center">
          <AlertCircle className="mr-2" />
          {error}
        </div>
      )}
      {success && (
        <div className="mt-4 p-3 bg-green-100 text-green-700 rounded flex items-center">
          <CheckCircle className="mr-2" />
          Ticket creado exitosamente.
        </div>
      )}
    </div>
  );
}