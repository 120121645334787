import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Menu, Moon, Sun, Home } from 'lucide-react';
import logoDark from '../logos/logo dark 1.png';
import logoLight from '../logos/logo.png';

export default function Header({ toggleSidebar, setActiveContent, isSidebarOpen }) {
  const { isDarkMode, toggleTheme } = useTheme();

  const handleHomeClick = () => {
    setActiveContent('home');
  };

  return (
    <header 
      className={`sticky top-0 z-50 w-full shadow-md transition-colors duration-300 
        ${isDarkMode 
          ? 'bg-gray-900 bg-opacity-50 text-white' 
          : 'bg-white bg-opacity-50 text-gray-800'
        } backdrop-filter backdrop-blur-lg`}
    >
      <div className="w-full px-4 py-2 flex items-center justify-between">
        <div className="flex items-center">
          <button
            onClick={toggleSidebar}
            className={`p-2 rounded-md transition-colors duration-200 mr-2
              ${isDarkMode 
                ? 'bg-gray-800 text-white hover:bg-gray-700' 
                : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
              }`}
            aria-label={isSidebarOpen ? "Cerrar barra lateral" : "Abrir barra lateral"}
            aria-expanded={isSidebarOpen}
          >
            <Menu className="h-5 w-5" />
          </button>
          <img 
            src={isDarkMode ? logoDark : logoLight} 
            alt="LMS Logo" 
            className="h-8 w-auto" 
          />
        </div>
        <div className="flex items-center space-x-2">
          <button 
            onClick={handleHomeClick}
            className={`p-2 rounded-md transition-colors duration-200 
              ${isDarkMode 
                ? 'bg-gray-800 text-white hover:bg-gray-700' 
                : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
              }`}
            aria-label="Ir a la página principal"
          >
            <Home className="h-5 w-5" />
          </button>
        </div>
      </div>
    </header>
  );
}