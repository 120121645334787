'use client'

import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ThemeProvider } from './contexts/ThemeContext'
import { UserProvider, useUser } from './UserContext'
import PasswordRecovery from './components/PasswordRecovery'
import Login from './components/Login'
import Layout from './components/Layout'
import PocketBase from 'pocketbase'

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL)
pb.autoCancellation(false)

export default function App() {
  return (
    <Router>
      <ThemeProvider>
        <UserProvider>
          <AppContent />
        </UserProvider>
      </ThemeProvider>
    </Router>
  )
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function(err) {
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}

function AppContent() {
  const { user, setUser } = useUser()
  const [loginError, setLoginError] = useState('')

  const handleLogin = async (username, password) => {
    try {
      const authData = await pb.collection('users').authWithPassword(username, password)
      console.log('Auth successful:', authData)
      setUser(authData.record)
      setLoginError('')
    } catch (error) {
      console.error('Error de inicio de sesión:', error)
      if (error.data) {
        console.error('Error details:', error.data)
      }
      setLoginError('Nombre de usuario o contraseña incorrectos. Por favor, intente de nuevo.')
    }
  }

  const handleLogout = () => {
    pb.authStore.clear()
    setUser(null)
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Routes>
        <Route path="/login" element={
          user ? <Navigate to="/" replace /> : <Login onLogin={handleLogin} error={loginError} />
        } />
        <Route path="/password-recovery" element={<PasswordRecovery pb={pb} />} />
        <Route path="/*" element={
          user ? <Layout userData={user} onLogout={handleLogout} pb={pb} /> : <Navigate to="/login" replace />
        } />
      </Routes>
    </div>
  )
}