'use client'
import { Settings } from 'lucide-react'
import React, { useState, useRef, useEffect } from 'react'
import { BarChart2, BookOpen, Calendar, ChevronDown, ChevronUp, ClipboardList, FileText, Gift, ListX, LogOut, MessageSquare, Menu, Repeat, User, X } from 'lucide-react'
import PocketBase from 'pocketbase'

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL)
pb.autoCancellation(false)

export default function Navigation({ 
  userData,
  onLogout,
  setActiveContent,
  activeContent,
  isSidebarOpen,
  toggleSidebar,
}) {
  const [isGroupOpen, setIsGroupOpen] = useState(false)
  const [avatarError, setAvatarError] = useState(false)
  const sidebarRef = useRef(null)

  const menuItems = [
    { name: 'Perfil', icon: User, content: 'profile' },
    { name: 'Lista de Oferta', icon: ListX, content: 'listadeoferta' },
    { name: 'Bandeja', icon: MessageSquare, content: 'bandeja-entrada' },
    { name: 'Evaluaciones', icon: BarChart2, content: 'evaluaciones' },
    { name: 'Rol Anual (Desarrollo)', icon: ClipboardList, content: 'rol-anual' },
    { name: 'Capacitaciones', icon: BookOpen, content: 'capacitaciones' },
    { name: 'Tickets', icon: FileText, content: 'crear-ticket' },
    { name: 'S.O.C.O.(Desarrollo)', icon: FileText, content: 'socos' },
    { name: 'Configuración', icon: Settings, content: 'configuracion' },
  ]

  const groupedItems = [
    { name: 'Solicitar PSG', icon: Calendar, content: 'solicitar-permiso' },
    { name: 'Regalar Guardia', icon: Gift, content: 'regalar-guardia' },
    { name: 'Cambio de Guardias', icon: Repeat, content: 'cambio-guardias' },
  ]

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && isSidebarOpen) {
        toggleSidebar()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isSidebarOpen, toggleSidebar])

  const renderAvatar = () => {
    if (userData.avatar && !avatarError) {
      return (
        <div className="w-16 h-16 rounded-full overflow-hidden border-2 border-blue-500 shadow-sm flex-shrink-0">
          <img
            src={pb.getFileUrl(userData, userData.avatar)}
            alt={`Avatar de ${userData.name}`}
            className="w-full h-full object-cover"
            onError={() => setAvatarError(true)}
          />
        </div>
      )
    } else {
      return (
        <div className="w-16 h-16 rounded-full bg-blue-200 flex items-center justify-center text-white text-2xl font-bold shadow-sm flex-shrink-0">
          {userData.name.charAt(0).toUpperCase()}
        </div>
      )
    }
  }

  const renderMenuContent = () => (
    <div className="flex flex-col pt-12 h-full">
      <div className="flex-shrink-0 p-4">
        <div className="flex flex-col items-center space-y-2">
          {renderAvatar()}
          <div className="text-center">
            <h2 className="text-lg font-semibold truncate">{userData.name}</h2>
            <p className="text-sm text-gray-500 dark:text-gray-400 truncate">{userData.username}</p>
            <p className="text-sm text-gray-500 dark:text-gray-400 truncate">{userData.telefono}</p>
          </div>
        </div>
      </div>
      <nav className="flex-1 overflow-y-auto px-4 py-2">
        {menuItems.map((item) => (
          <button
            key={item.content}
            onClick={() => {
              setActiveContent(item.content)
              if (window.innerWidth < 1024) {
                toggleSidebar()
              }
            }}
            className={`flex items-center w-full px-4 py-2 text-left rounded-md mb-1 ${
              activeContent === item.content
                ? 'bg-blue-500 bg-opacity-70 text-white'
                : 'text-gray-600 hover:bg-gray-100 hover:bg-opacity-50 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:bg-opacity-50'
            }`}
          >
            <item.icon className="w-5 h-5 mr-3" />
            <span>{item.name}</span>
          </button>
        ))}
        <div className="mt-2">
          <button
            onClick={() => setIsGroupOpen(!isGroupOpen)}
            className="flex items-center justify-between w-full px-4 py-2 text-left rounded-md"
          >
            <span className="flex items-center">
              <Calendar className="w-5 h-5 mr-3" />
              <span>Guardias y Permisos</span>
            </span>
            {isGroupOpen ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
          </button>
          {isGroupOpen && (
            <div className="ml-4 mt-2">
              {groupedItems.map((item) => (
                <button
                  key={item.content}
                  onClick={() => {
                    setActiveContent(item.content)
                    if (window.innerWidth < 1024) {
                      toggleSidebar()
                    }
                  }}
                  className={`flex items-center w-full px-4 py-2 text-left rounded-md mb-1 ${
                    activeContent === item.content
                      ? 'bg-blue-500 bg-opacity-70 text-white'
                      : 'text-gray-600 hover:bg-gray-100 hover:bg-opacity-50 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:bg-opacity-50'
                  }`}
                >
                  <item.icon className="w-5 h-5 mr-3" />
                  <span>{item.name}</span>
                </button>
              ))}
            </div>
          )}
        </div>
      </nav>
      <div className="flex-shrink-0 p-4 border-t border-gray-200 dark:border-gray-700">
        <button
          onClick={onLogout}
          className="flex items-center w-full px-4 py-2 text-left text-red-600 hover:bg-red-100 hover:bg-opacity-50 dark:hover:bg-red-900 dark:hover:bg-opacity-50 rounded-md"
        >
          <LogOut className="w-5 h-5 mr-3" />
          <span>Cerrar sesión</span>
        </button>
      </div>
    </div>
  )

  return (
    <>
      {/* Overlay para cuando la barra lateral está abierta */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleSidebar}
        ></div>
      )}
      
      {/* Barra lateral */}
      <aside 
        ref={sidebarRef}
        className={`fixed top-0 left-0 z-50 h-full w-64 bg-white bg-opacity-70 dark:bg-gray-800 dark:bg-opacity-70 shadow-lg transition-transform duration-300 ease-in-out transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } backdrop-blur-md`}
      >
        <button
          onClick={toggleSidebar}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <X className="w-6 h-6" />
        </button>
        {renderMenuContent()}
      </aside>
    </>
  )
}