import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import PocketBase from 'pocketbase'
import { useUser } from '../../UserContext'
import { useTheme } from '../../contexts/ThemeContext'
import { ArrowRightIcon, CheckIcon, XMarkIcon, SunIcon, MoonIcon } from '@heroicons/react/24/solid'
import Lottie from 'react-lottie-player'
import checkAnimation from '../../asssets/check-animation.json' 

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL)
pb.autoCancellation(false)

function CederGuardia() {
  const { user } = useUser()
  const { isDarkMode, toggleTheme } = useTheme()
  const [misPeticiones, setMisPeticiones] = useState([])
  const [solicitudesRecibidas, setSolicitudesRecibidas] = useState([])
  const [solicitudesParaAprobar, setSolicitudesParaAprobar] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('misPeticiones')
  const [showAnimation, setShowAnimation] = useState(false)
  const [animatingCardId, setAnimatingCardId] = useState(null)

  useEffect(() => {
    if (user) {
      fetchSolicitudes()
    }
  }, [user])

  const fetchSolicitudes = async () => {
    setIsLoading(true)
    try {
      const [peticiones, recibidas, paraAprobar] = await Promise.all([
        pb.collection('permisos_cambio__guardia').getList(1, 100, {
          filter: `usuario_de_peticion = "${user.id}"`,
          sort: '-created',
          expand: 'division_peticion,usuario_de_receptor,division_receptor,usuario_de_aprobador',
        }),
        pb.collection('permisos_cambio__guardia').getList(1, 100, {
          filter: `usuario_de_receptor = "${user.id}"`,
          sort: '-created',
          expand: 'division_peticion,usuario_de_peticion,division_receptor,usuario_de_aprobador',
        }),
        pb.collection('permisos_cambio__guardia').getList(1, 100, {
          filter: `usuario_de_aprobador = "${user.id}" && receptor_deacuerdo = "deacuerdo"`,
          sort: '-created',
          expand: 'division_peticion,usuario_de_peticion,division_receptor,usuario_de_receptor',
        }),
      ])
      setMisPeticiones(peticiones.items)
      setSolicitudesRecibidas(recibidas.items)
      setSolicitudesParaAprobar(paraAprobar.items)
    } catch (error) {
      console.error('Error fetching solicitudes:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const updateReceptorDeacuerdo = async (solicitudId, newValue) => {
    try {
      await pb.collection('permisos_cambio__guardia').update(solicitudId, {
        receptor_deacuerdo: newValue
      })
      if (newValue === 'deacuerdo') {
        setShowAnimation(true)
        setAnimatingCardId(solicitudId)
        setTimeout(() => {
          setShowAnimation(false)
          setAnimatingCardId(null)
        }, 2000)
      }
      await fetchSolicitudes()
    } catch (error) {
      console.error('Error updating solicitud:', error)
    }
  }

  const updateStatus = async (solicitudId, newStatus) => {
    try {
      await pb.collection('permisos_cambio__guardia').update(solicitudId, {
        status: newStatus,
        fecha_de_permiso: new Date().toISOString().split('T')[0],
      })
      await fetchSolicitudes()
    } catch (error) {
      console.error('Error updating solicitud status:', error)
    }
  }

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd MMM yyyy', { locale: es })
  }

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'aprobado': return 'bg-green-300 text-green-800 border-green-500 dark:bg-green-800 dark:text-green-100 dark:border-green-700'
      case 'rechazado': return 'bg-red-300 text-red-800 border-red-500 dark:bg-red-800 dark:text-red-100 dark:border-red-700'
      default: return 'bg-yellow-100 text-yellow-800 border-yellow-500 dark:bg-yellow-800 dark:text-yellow-100 dark:border-yellow-700'
    }
  }

  const renderSolicitudCard = (solicitud, type) => (
    <div key={solicitud.id} className={`bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mb-6 relative ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
      {showAnimation && animatingCardId === solicitud.id && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
          <Lottie
            loop
            animationData={checkAnimation}
            play
            style={{ width: 150, height: 150 }}
          />
        </div>
      )}
      <div className="flex justify-between items-center mb-4">
        <span className={`px-3 py-1 rounded-full text-sm font-semibold ${getStatusColor(solicitud.status)} border`}>
          {solicitud.status}
        </span>
        <span className="text-sm text-gray-600 dark:text-gray-400">
          ID: {solicitud.id}
        </span>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="bg-blue-50 dark:bg-blue-900 p-4 rounded-lg">
          <h3 className="font-semibold text-blue-800 dark:text-blue-200 mb-2">Información de la Petición</h3>
          <p className="text-sm text-gray-700 dark:text-gray-300">Usuario: {solicitud.expand?.usuario_de_peticion?.name || 'No especificado'}</p>
          <p className="text-sm text-gray-700 dark:text-gray-300">División: {solicitud.expand?.division_peticion?.name || 'No especificada'}</p>
          <p className="text-sm text-gray-700 dark:text-gray-300">Fecha: {formatDate(solicitud.fecha_peticion)}</p>
        </div>

        <div className="bg-green-50 dark:bg-green-900 p-4 rounded-lg">
          <h3 className="font-semibold text-green-800 dark:text-green-200 mb-2">Información de Recepción</h3>
          <p className="text-sm text-gray-700 dark:text-gray-300">División: {solicitud.expand?.division_receptor?.name || 'No especificada'}</p>
          <p className="text-sm text-gray-700 dark:text-gray-300">Fecha: {solicitud.fecha_receptor ? formatDate(solicitud.fecha_receptor) : 'No especificada'}</p>
          {type !== 'solicitudesRecibidas' && (
            <p className="text-sm text-gray-700 dark:text-gray-300">Receptor: {solicitud.expand?.usuario_de_receptor?.name || 'No asignado'}</p>
          )}
        </div>
      </div>

      {type === 'misPeticiones' && (
        <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
          <h3 className="font-semibold text-gray-800 dark:text-gray-200 mb-2">Información Adicional</h3>
          <p className="text-sm text-gray-700 dark:text-gray-300">Aprobador: {solicitud.expand?.usuario_de_aprobador?.name || 'No asignado'}</p>
          <p className="text-sm text-gray-700 dark:text-gray-300">Receptor de acuerdo: {solicitud.receptor_deacuerdo || 'Pendiente'}</p>
        </div>
      )}

      {type === 'solicitudesRecibidas' && (
        <div className="mt-4">
          <p className="text-sm font-semibold mb-2">¿Estás de acuerdo con el Cambio de guardia?</p>
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => updateReceptorDeacuerdo(solicitud.id, 'deacuerdo')}
              className={`px-4 py-2 rounded text-sm font-medium flex items-center ${
                solicitud.receptor_deacuerdo === 'deacuerdo'
                  ? 'bg-green-500 text-white'
                  : 'bg-gray-200 text-gray-800 hover:bg-green-100 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-green-800'
              }`}
            >
              <CheckIcon className="w-4 h-4 mr-2" />
              De acuerdo
            </button>
            <button
              onClick={() => updateReceptorDeacuerdo(solicitud.id, 'desacuerdo')}
              className={`px-4 py-2 rounded text-sm font-medium flex items-center ${
                solicitud.receptor_deacuerdo === 'desacuerdo'
                  ? 'bg-red-500 text-white'
                  : 'bg-gray-200 text-gray-800 hover:bg-red-100 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-red-800'
              }`}
            >
              <XMarkIcon className="w-4 h-4 mr-2" />
              En desacuerdo
            </button>
          </div>
        </div>
      )}

      {type === 'solicitudesParaAprobar' && (
        <div className="mt-4">
          <p className="text-sm font-semibold mb-2">Actualizar estado:</p>
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => updateStatus(solicitud.id, 'Aprobado')}
              className={`px-4 py-2 rounded text-sm font-medium flex items-center ${
                solicitud.status === 'Aprobado'
                  ? 'bg-green-500 text-white'
                  : 'bg-gray-200 text-gray-800 hover:bg-green-100 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-green-800'
              }`}
            >
              <CheckIcon className="w-4 h-4 mr-2" />
              Aprobar
            </button>
            <button
              onClick={() => updateStatus(solicitud.id, 'Rechazado')}
              className={`px-4 py-2 rounded text-sm font-medium flex items-center ${
                solicitud.status === 'Rechazado'
                  ? 'bg-red-500 text-white'
                  : 'bg-gray-200 text-gray-800 hover:bg-red-100 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-red-800'
              }`}
            >
              <XMarkIcon className="w-4 h-4 mr-2" />
              Rechazar
            </button>
          </div>
        </div>
      )}
    </div>
  )

  return (
    <div className={`min-h-screen p-4 md:p-8 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-800'}`}>
              <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-bold">Intercambio de Guardias</h1>
        </div>
      <div className="flex mb-8 overflow-x-auto">
        <button
          className={`mr-4 py-2 px-4 text-sm font-medium focus:outline-none transition-colors duration-200 ${
            activeTab === 'misPeticiones' 
              ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400' 
              : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'
          }`}
          onClick={() => setActiveTab('misPeticiones')}
        >
          Mis Peticiones
        </button>
        <button
          className={`mr-4 py-2 px-4 text-sm font-medium focus:outline-none transition-colors duration-200 ${
            activeTab === 'solicitudesRecibidas' 
              ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400' 
              : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'
          }`}
          onClick={() => setActiveTab('solicitudesRecibidas')}
        >
          Solicitudes Recibidas
        </button>
        <button
          className={`py-2 px-4 text-sm font-medium focus:outline-none transition-colors duration-200 ${
            activeTab === 'solicitudesParaAprobar' 
              ? 'text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400' 
              : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'
          }`}
          onClick={() => setActiveTab('solicitudesParaAprobar')}
        >
          Solicitudes para Aprobar
        </button>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 dark:border-blue-400"></div>
        </div>
      ) : (
        <div className="space-y-6">
          {activeTab === 'misPeticiones' && misPeticiones.map(solicitud => renderSolicitudCard(solicitud, 'misPeticiones'))}
          {activeTab === 'solicitudesRecibidas' && solicitudesRecibidas.map(solicitud => renderSolicitudCard(solicitud, 'solicitudesRecibidas'))}
          {activeTab === 'solicitudesParaAprobar' && solicitudesParaAprobar.map(solicitud => renderSolicitudCard(solicitud, 'solicitudesParaAprobar'))}
        </div>
      )}
    </div>
  )
}

export default CederGuardia