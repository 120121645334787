import React, { useState, useEffect, useRef, useCallback } from 'react';
import PocketBase from 'pocketbase';
import { useTheme } from '../contexts/ThemeContext';
import { Calendar, Loader, Building, Award, AlertCircle, ChevronLeft, ChevronRight, Info } from 'lucide-react';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const DAYS_OF_WEEK = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];
const MONTHS = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const divisionColors = {
  'Gestion de Calidad': { text: 'text-white-900', bg: 'bg-teal-500' },
  'Quimica': { text: 'text-red-800', bg: 'bg-red-500' },
  'Hematologia': { text: 'text-blue-800', bg: 'bg-blue-500' },
  'Microbiologia': { text: 'text-green-800', bg: 'bg-green-500' },
  'Biologia Molecular': { text: 'text-yellow-800', bg: 'bg-yellow-500' },
  'Inmunologia': { text: 'text-purple-800', bg: 'bg-purple-500' },
  'Hormonas': { text: 'text-white-800', bg: 'bg-pink-500' },
  'Banco de Sangre': { text: 'text-indigo-800', bg: 'bg-indigo-500' },
  'Emergencias': { text: 'text-orange-800', bg: 'bg-orange-500' },
  'Laboratorio': { text: 'text-gray-800', bg: 'bg-gray-500' },
  'Preanalitico': { text: 'text-cyan-800', bg: 'bg-cyan-500' }
};

const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export default function Evaluaciones({ userData }) {
  const [divisionWorkingDays, setDivisionWorkingDays] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [calendarData, setCalendarData] = useState([]);
  const [hasData, setHasData] = useState(true);
  const { isDarkMode } = useTheme();
  const [currentMonthIndex, setCurrentMonthIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const containerRef = useRef(null);
  const resizeObserverRef = useRef(null);

  const handleResize = useCallback(debounce(() => {
    setCurrentMonthIndex(prevIndex => prevIndex);
  }, 100), []);

  useEffect(() => {
    if (containerRef.current) {
      resizeObserverRef.current = new ResizeObserver(handleResize);
      resizeObserverRef.current.observe(containerRef.current);
    }

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [handleResize]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchWorkingDays = async () => {
      try {
        await pb.admins.authWithPassword(
          process.env.REACT_APP_ADMIN_EMAIL,
          process.env.REACT_APP_ADMIN_PASSWORD
        );

        const records = await pb.collection('calendarioRolUbicacion').getList(1, 1000, {
          filter: `usuarioAsignar = "${userData.id}"`,
          sort: '+fecha_inicio',
          expand: 'division,usuarioAsignar',
          $autoCancel: false,
          signal: controller.signal,
        });

        if (!isMounted) return;

        if (records.items.length === 0) {
          setHasData(false);
          setIsLoading(false);
          return;
        }

        let divisionDays = {};

        const filteredRecords = records.items.filter(record => record.usuarioAsignar === userData.id);

        filteredRecords.forEach((record) => {
          const startDate = new Date(record.fecha_inicio);
          const endDate = new Date(record.fecha_final);
          startDate.setHours(startDate.getHours() - 6);
          endDate.setHours(endDate.getHours() - 6);

          const daysDiff = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
          const divisionName = record.expand?.division?.name || 'Sin división';
          let divisionWorkDays = 0;

          for (let i = 0; i < daysDiff; i++) {
            const currentDate = new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000);
            if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
              divisionWorkDays++;
            }
          }

          divisionDays[divisionName] = (divisionDays[divisionName] || 0) + divisionWorkDays;
        });

        if (isMounted) {
          setDivisionWorkingDays(divisionDays);
          setCalendarData(filteredRecords);
          setIsLoading(false);
        }
      } catch (err) {
        if (err.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Error fetching working days:', err);
          if (isMounted) {
            setError('Error al cargar los días trabajados. Por favor, intente de nuevo más tarde.');
            setIsLoading(false);
          }
        }
      }
    };

    fetchWorkingDays();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [userData.id]);

  const getDivisionWithMostDays = () => {
    const entries = Object.entries(divisionWorkingDays);
    if (entries.length === 0) {
      return 'Sin división';
    }
    return entries.reduce((a, b) => a[1] > b[1] ? a : b)[0];
  };

  const adjustDate = (date) => {
    const adjustedDate = new Date(date);
    adjustedDate.setHours(0, 0, 0, 0);
    return adjustedDate;
  };

  const getMonthData = (monthIndex) => {
    if (calendarData.length === 0) return null;

    const firstAssignment = new Date(calendarData[0].fecha_inicio);
    const lastAssignment = new Date(calendarData[calendarData.length - 1].fecha_final);
    const currentDate = new Date(firstAssignment.getFullYear(), firstAssignment.getMonth() + monthIndex, 1);

    if (currentDate > lastAssignment) return null;

    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);

    const days = [];
    for (let i = 0; i < firstDayOfMonth.getDay(); i++) {
      days.push(null);
    }
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      days.push(new Date(year, month, i));
    }

    return { month, year, days };
  };

  const isDateAssigned = (date) => {
    return calendarData.some(assignment => {
      const start = adjustDate(new Date(assignment.fecha_inicio));
      const end = adjustDate(new Date(assignment.fecha_final));
      const checkDate = new Date(date);
      checkDate.setHours(0, 0, 0, 0);
      return checkDate >= start && checkDate <= end;
    });
  };

  const getDivisionForDate = (date) => {
    const assignment = calendarData.find(a => {
      const start = adjustDate(new Date(a.fecha_inicio));
      const end = adjustDate(new Date(a.fecha_final));
      const checkDate = new Date(date);
      checkDate.setHours(0, 0, 0, 0);
      return checkDate >= start && checkDate <= end;
    });
    return assignment ? assignment.expand?.division?.name || 'Sin división' : null;
  };

  const renderMonth = (monthData) => {
    if (!monthData) return null;

    return (
      <div className="mb-4">
        <h4 className="text-xl font-bold mb-2">{MONTHS[monthData.month]} {monthData.year}</h4>
        <div className="min-h-[300px]"> {/* Add this wrapper div */}
          <div className="grid grid-cols-7 gap-1">
            {DAYS_OF_WEEK.map(day => (
              <div key={day} className="text-center font-bold p-1 text-xs">{day}</div>
            ))}
            {monthData.days.map((day, index) => {
              const division = day ? getDivisionForDate(day) : null;
              const colors = division ? divisionColors[division] || { text: 'text-gray-800', bg: 'bg-gray-200' } : null;
              return (
                <div
                  key={index}
                  className={`p-1 text-center rounded-md ${
                    day
                      ? isDateAssigned(day)
                        ? `${colors.bg} ${colors.text} cursor-pointer hover:opacity-80`
                        : ''
                      : 'opacity-0'
                  }`}
                  onClick={() => day && isDateAssigned(day) && setSelectedDate(day)}
                >
                  {day && (
                    <>
                      <span className="block text-xs">{day.getDate()}</span>
                      {isDateAssigned(day) && (
                        <Info className="w-3 h-3 mx-auto mt-1" />
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin h-12 w-12 text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className={`mx-auto p-2 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg shadow-lg`}>
        <div className="flex items-center text-red-500 mb-2">
          <Calendar className="mr-2" />
          <span>{error}</span>
        </div>
      </div>
    );
  }

  if (!hasData) {
    return (
      <div className={`mx-auto p-3 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg shadow-lg`}>
        <div className="flex items-center justify-center text-yellow-500 mb-2">
          <AlertCircle className="mr-2" size={24} />
          <span className="text-lg font-semibold">No hay datos disponibles para este usuario.</span>
        </div>
        <p className="text-center">
          No se han encontrado registros de tiempo trabajado para este usuario. 
          Por favor, asegúrese de que se han asignado tareas o roles a este usuario.
        </p>
      </div>
    );
  }

  const divisionWithMostDays = getDivisionWithMostDays();

  return (
    <div ref={containerRef} className={`mx-auto p-3 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg shadow-lg`}>
      <h2 className="text-xl font-bold mb-3 flex items-center">
        <Calendar className="mr-2" />
        Tiempo Trabajado
      </h2>

      <h3 className="text-lg font-bold mb-2 flex items-center">
        <Building className="mr-2" />
        División para Evaluación
      </h3>

      <div className={`p-2 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} border-2 border-yellow-500 mb-3`}>
        <h4 className="font-bold mb-1 flex items-center justify-between">
          {divisionWithMostDays}
          <span className="bg-yellow-500 text-black text-xs font-semibold px-2 py-1 rounded-full flex items-center">
            <Award className="w-3 h-3 mr-1" />
            Evaluación
          </span>
        </h4>
        <p className="text-xl font-bold">{divisionWorkingDays[divisionWithMostDays]} días</p>
      </div>

      <h3 className="text-lg font-bold mb-2 flex items-center">
        <Calendar className="mr-2" />
        Línea de Tiempo
      </h3>

      <div className="relative h-[400px] overflow-y-auto">
        <button
          onClick={() => setCurrentMonthIndex(prev => Math.max(0, prev - 1))}
          className="absolute left-0 top-16 z-10 bg-gray-200 dark:bg-gray-700 p-1 rounded-full shadow-md"
          aria-label="Mes anterior"
          disabled={currentMonthIndex === 0}
        >
          <ChevronLeft className="w-4 h-4" />
        </button>
        <button
          onClick={() => setCurrentMonthIndex(prev => prev + 1)}
          className="absolute right-0 top-16 z-10 bg-gray-200 dark:bg-gray-700 p-1 rounded-full shadow-md"
          aria-label="Mes siguiente"
          disabled={!getMonthData(currentMonthIndex + 1)}
        >
          <ChevronRight className="w-4 h-4" />
        </button>
        <div className="overflow-x-auto hide-scrollbar pt-2">
          {renderMonth(getMonthData(currentMonthIndex))}
        </div>
      </div>

      {selectedDate && (
        <div className={`mt-2 p-2 rounded-lg ${
          isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
        }`}>
          <h4 className="font-bold mb-1">Detalles para {selectedDate.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' })}</h4>
          <p>División: {getDivisionForDate(selectedDate) || 'No asignado'}</p>
        </div>
      )}
    </div>
  );
}