'use client'

import React, { useState, useEffect, useCallback, useRef } from 'react'
import PocketBase from 'pocketbase'
import LoadingAnimation from './LoadingAnimation'
import { AlertTriangle, CheckCircle, Clock, XCircle, BookOpen, Award, TrendingUp, ChevronRight, ChevronDown } from 'lucide-react'
import { useTheme } from '../contexts/ThemeContext'

const statusIcons = {
  'Aprobado': <CheckCircle className="w-5 h-5 text-green-500" />,
  'En Curso': <Clock className="w-5 h-5 text-yellow-500" />,
  'Reprobado': <XCircle className="w-5 h-5 text-red-500" />
}

const statusEmojis = {
  'Aprobado': '🎉',
  'En Curso': '💪',
  'Reprobado': '😓'
}

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL)
const MAX_RETRIES = 3
const RETRY_DELAY = 1000 // 1 second

export default function Capacitaciones({ userData }) {
  const { isDarkMode } = useTheme()
  const [enrollmentsByDivision, setEnrollmentsByDivision] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [activeDivision, setActiveDivision] = useState(null)
  const [activeStatus, setActiveStatus] = useState('Todos')
  const [showMotivationalMessage, setShowMotivationalMessage] = useState(false)
  const abortControllerRef = useRef(null)
  const isMountedRef = useRef(true)

  const cargarEnrollments = useCallback(async (retryCount = 0) => {
    if (!isMountedRef.current) return
    setLoading(true)
    setError(null)
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    abortControllerRef.current = new AbortController()

    try {
      if (!userData || !userData.id) {
        throw new Error('User data is not available')
      }
      if (!pb.authStore.isValid) {
        await pb.collection('users').authRefresh()
      }
      const records = await pb.collection('enrollments').getList(1, 50, {
        filter: `user = "${userData.id}"`,
        expand: 'course,course.division,responsable',
        $autoCancel: false,
        signal: abortControllerRef.current.signal
      })

      if (!isMountedRef.current) return

      const enrollmentsByDiv = {}
      records.items.forEach(enrollment => {
        const divisionName = enrollment.expand?.course?.expand?.division?.name || 'Sin División'
        if (!enrollmentsByDiv[divisionName]) {
          enrollmentsByDiv[divisionName] = { 'Todos': [], 'Aprobado': [], 'En Curso': [], 'Reprobado': [] }
        }
        enrollmentsByDiv[divisionName]['Todos'].push(enrollment)
        enrollmentsByDiv[divisionName][enrollment.approved].push(enrollment)
      })

      setEnrollmentsByDivision(enrollmentsByDiv)
      if (Object.keys(enrollmentsByDiv).length > 0) {
        setActiveDivision(Object.keys(enrollmentsByDiv)[0])
      }
    } catch (err) {
      if (!isMountedRef.current) return
      console.error('Error al cargar las inscripciones:', err)
      if (err.name === 'AbortError' || err.status === 0) {
        if (retryCount < MAX_RETRIES) {
          console.log(`Retry attempt ${retryCount + 1}`)
          setTimeout(() => cargarEnrollments(retryCount + 1), RETRY_DELAY)
          return
        }
      }
      setError(err.message)
    } finally {
      if (isMountedRef.current) {
        setLoading(false)
      }
    }
  }, [userData])

  useEffect(() => {
    isMountedRef.current = true
    cargarEnrollments()
    return () => {
      isMountedRef.current = false
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
    }
  }, [cargarEnrollments])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMountedRef.current) {
        setShowMotivationalMessage(true)
      }
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  if (loading) return <LoadingAnimation />

  if (error) {
    return (
      <div className={`${isDarkMode ? 'bg-red-900' : 'bg-red-100'} border-l-4 border-red-500 text-red-700 p-4 rounded-md shadow-md`} role="alert">
        <div className="flex items-center">
          <AlertTriangle className="w-6 h-6 mr-2" />
          <p><strong className="font-bold">Error:</strong> {error}</p>
        </div>
        <button
          onClick={() => cargarEnrollments()}
          className={`mt-4 ${isDarkMode ? 'bg-red-700 hover:bg-red-600' : 'bg-red-500 hover:bg-red-600'} text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out`}
        >
          Intentar de nuevo
        </button>
      </div>
    )
  }

  if (Object.keys(enrollmentsByDivision).length === 0) {
    return (
      <div className={`text-center p-4 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
        <h2 className="text-2xl font-bold mb-4">No tienes inscripciones a capacitaciones</h2>
        <p>Cuando te inscribas a una capacitación, aparecerá aquí.</p>
      </div>
    )
  }

  return (
    <div className={`space-y-6 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
      <h2 className="text-3xl font-bold mb-6 font-outfit flex items-center">
        <BookOpen className="mr-2" />
        Tus Inscripciones a Capacitaciones
      </h2>

      {showMotivationalMessage && (
        <div className={`${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-blue-100 text-blue-700'} border-l-4 border-blue-500 p-4 rounded-md shadow-md mb-6`}>
          <p className="font-semibold">¡Sigue adelante! 🚀</p>
          <p>Cada capacitación te acerca más a tus metas profesionales.</p>
        </div>
      )}

      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-xl overflow-hidden`}>
        <div className="p-6">
          <div className="flex flex-wrap gap-2 mb-6">
            {Object.keys(enrollmentsByDivision).map((division) => (
              <button
                key={division}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-300 ${
                  activeDivision === division
                    ? isDarkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white'
                    : isDarkMode ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
                onClick={() => setActiveDivision(division)}
              >
                {division}
              </button>
            ))}
          </div>

          {activeDivision && enrollmentsByDivision[activeDivision] && (
            <>
              <div className="flex flex-wrap gap-2 mb-6">
                {['Todos', 'Aprobado', 'Reprobado', 'En Curso'].map((status) => (
                  <button
                    key={status}
                    className={`px-3 py-1 rounded-full text-xs font-medium transition-colors duration-300 ${
                      activeStatus === status
                        ? isDarkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white'
                        : isDarkMode ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                    onClick={() => setActiveStatus(status)}
                  >
                    {status}
                  </button>
                ))}
              </div>

              <div className="space-y-4">
                {enrollmentsByDivision[activeDivision][activeStatus].map((enrollment) => (
                  <div
                    key={enrollment.id}
                    className={`${
                      isDarkMode ? 'bg-gray-700' : 'bg-white'
                    } rounded-lg p-4 shadow-md hover:shadow-lg transition duration-300 ease-in-out`}
                  >
                    <div className="flex items-center justify-between mb-2">
                      <h4 className="text-lg font-semibold font-outfit">
                        {enrollment.expand?.course?.name || 'Nombre del curso no disponible'}
                      </h4>
                      <div className="flex items-center">
                        {statusIcons[enrollment.approved]}
                        <span className="ml-2 text-sm">
                          {enrollment.approved} {statusEmojis[enrollment.approved]}
                        </span>
                      </div>
                    </div>
                    <div className="text-sm space-y-1">
                      <p>
                        Fecha de inscripción:{' '}
                        {enrollment.enrollmentDate ? new Date(enrollment.enrollmentDate).toLocaleDateString() : 'No disponible'}
                      </p>
                      {enrollment.completionDate && (
                        <p>
                          Fecha de finalización:{' '}
                          {new Date(enrollment.completionDate).toLocaleDateString()}
                        </p>
                      )}
                      {enrollment.expirationDate && (
                        <p>
                          Fecha de expiración:{' '}
                          {new Date(enrollment.expirationDate).toLocaleDateString()}
                        </p>
                      )}
                      <p>
                        Responsable: {enrollment.expand?.responsable?.name || 'No especificado'}
                      </p>
                    </div>
                    {enrollment.approved === 'Aprobado' && (
                      <div className="mt-2 flex items-center text-green-500">
                        <Award className="w-4 h-4 mr-1" />
                        <span className="text-xs">¡Felicidades por completar este curso!</span>
                      </div>
                    )}
                    {enrollment.approved === 'En Curso' && (
                      <div className="mt-2 flex items-center text-yellow-500">
                        <TrendingUp className="w-4 h-4 mr-1" />
                        <span className="text-xs">¡Sigue así, vas por buen camino!</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}