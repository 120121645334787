import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PocketBase from 'pocketbase';
import { useTheme } from '../contexts/ThemeContext';
import { AlertCircle } from 'lucide-react';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const predefinedReasons = [
  { id: 1, label: 'Ausencia completa 6am a 3pm' },
  { id: 2, label: 'Ausencia completa 5:30am a 2:30pm' },
  { id: 3, label: 'Ausencia completa 7am a 4pm' },
  { id: 4, label: 'Ausencia completa 10am a 7pm' },
  { id: 5, label: 'Ausencia completa 2pm a 10pm' },
  { id: 6, label: 'Ausencia completa 10pm a 6am' },
  { id: 7, label: 'Ausencia parcial (motivo)' },
];

export default function PermissionRequest({ userData }) {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [approvers, setApprovers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [loadingError, setLoadingError] = useState(null);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchApprovers = async () => {
      try {
        console.log('Authenticating admin...');
        await pb.admins.authWithPassword(
          process.env.REACT_APP_ADMIN_EMAIL,
          process.env.REACT_APP_ADMIN_PASSWORD
        );
        console.log('Admin authenticated successfully');
        console.log('Fetching approvers...');
        const resultList = await pb.collection('users').getList(1, 50, {
          filter: 'role = "jefatura"',
          sort: 'name',
          $autoCancel: false,
          signal: controller.signal,
        });
        console.log('Approvers fetched:', resultList);
        if (isMounted) {
          if (resultList.items.length === 0) {
            console.warn('No approvers found');
            setLoadingError('No se encontraron jefaturas. Por favor, contacte al administrador.');
          } else {
            setApprovers(resultList.items);
          }
        }
      } catch (error) {
        console.error('Error in fetchApprovers:', error);
        if (isMounted && error.name !== 'AbortError') {
          setLoadingError('Error al cargar las jefaturas. Por favor, intente de nuevo más tarde.');
        }
      }
    };

    fetchApprovers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setSubmitMessage('');
    try {
      console.log('Authenticating admin for submission...');
      await pb.admins.authWithPassword(
        process.env.REACT_APP_ADMIN_EMAIL,
        process.env.REACT_APP_ADMIN_PASSWORD
      );
      console.log('Admin authenticated successfully for submission');

      const selectedReasons = predefinedReasons
        .filter((reason, index) => data[`reason_${index}`])
        .map(reason => reason.label);
      if (data.customReason) {
        selectedReasons.push(data.customReason);
      }
      const finalReason = selectedReasons.join(', ');

      // Add 7 hours to the start and end dates
      const adjustDate = (dateString) => {
        const date = new Date(dateString);
        date.setHours(date.getHours() + 7);
        return date.toISOString();
      };

      const adjustedStartDate = adjustDate(data.start_date);
      const adjustedEndDate = adjustDate(data.end_date);

      console.log('Submitting permission request:', {
        ...data,
        reason: finalReason,
        start_date: adjustedStartDate,
        end_date: adjustedEndDate
      });

      const permissionData = {
        requester: userData.id,
        approver: data.approver,
        start_date: adjustedStartDate,
        end_date: adjustedEndDate,
        reason: finalReason,
        status: 'Pendiente',
        created_at: new Date().toISOString(),
      };

      const record = await pb.collection('permission_requests').create(permissionData);
      console.log('Permission request created:', record);
      alert('Recordatorio Se va a requerir el papel durante el periodo de prueba de la aplicacion att:Direccion de Laboratorio');
      setSubmitMessage('Solicitud de permiso enviada con éxito');
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
      setSubmitMessage('Error al enviar la solicitud. Por favor, inténtelo de nuevo.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loadingError) {
    return (
      <div className={`max-w-md mx-auto rounded-xl shadow-md overflow-hidden md:max-w-2xl m-4 p-8 ${
        isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
      }`}>
        <div className="text-red-500">{loadingError}</div>
      </div>
    );
  }

  return (
    <div className={`max-w-md mx-auto rounded-xl shadow-md overflow-hidden md:max-w-2xl m-4 ${
      isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
    }`}>
      <div className="p-8">
        <div className={`uppercase tracking-wide text-sm font-semibold mb-4 ${
          isDarkMode ? 'text-blue-400' : 'text-indigo-500'
        }`}>
          Solicitud de Permiso
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label htmlFor="start_date" className="block text-sm font-medium">Fecha de inicio</label>
            <input
              type="date"
              id="start_date"
              {...register("start_date", { required: "Este campo es requerido" })}
              className={`mt-1 block w-full rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
                isDarkMode
                  ? 'bg-gray-700 border-gray-600 text-white focus:border-blue-500 focus:ring-blue-500'
                  : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'
              }`}
            />
            {errors.start_date && <span className="text-red-500 text-xs">{errors.start_date.message}</span>}
          </div>
          <div>
            <label htmlFor="end_date" className="block text-sm font-medium">Fecha de fin</label>
            <input
              type="date"
              id="end_date"
              {...register("end_date", { required: "Este campo es requerido" })}
              className={`mt-1 block w-full rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
                isDarkMode
                  ? 'bg-gray-700 border-gray-600 text-white focus:border-blue-500 focus:ring-blue-500'
                  : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'
              }`}
            />
            {errors.end_date && <span className="text-red-500 text-xs">{errors.end_date.message}</span>}
          </div>
          <div>
            <label className="block text-sm font-medium">Motivo de la Ausencia</label>
            <div className="mt-2 space-y-2">
              {predefinedReasons.map((reason, index) => (
                <div key={reason.id} className="flex items-center">
                  <input
                    type="checkbox"
                    id={`reason_${index}`}
                    {...register(`reason_${index}`)}
                    className={`rounded shadow-sm focus:ring focus:ring-opacity-50 ${
                      isDarkMode
                        ? 'bg-gray-700 border-gray-600 text-blue-600 focus:border-blue-500 focus:ring-blue-500'
                        : 'border-gray-300 text-indigo-600 focus:border-indigo-300 focus:ring-indigo-200'
                    }`}
                  />
                  <label htmlFor={`reason_${index}`} className="ml-2 block text-sm">
                    {reason.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div>
            <label htmlFor="customReason" className="block text-sm font-medium">Otro Motivo (Opcional)</label>
            <textarea
              id="customReason"
              {...register("customReason")}
              className={`mt-1 block w-full rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
                isDarkMode
                  ? 'bg-gray-700 border-gray-600 text-white focus:border-blue-500 focus:ring-blue-500'
                  : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'
              }`}
              rows="3"
            ></textarea>
          </div>
          <div>
            <label htmlFor="approver" className="block text-sm font-medium">Jefatura responsable</label>
            <select
              id="approver"
              {...register("approver", { required: "Este campo es requerido" })}
              className={`mt-1 block w-full rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
                isDarkMode
                  ? 'bg-gray-700 border-gray-600 text-white focus:border-blue-500 focus:ring-blue-500'
                  : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'
              }`}
            >
              <option value="">Seleccione una jefatura</option>
              {approvers.map(approver => (
                <option key={approver.id} value={approver.id}>{approver.name}</option>
              ))}
            </select>
            {errors.approver && <span className="text-red-500 text-xs">{errors.approver.message}</span>}
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
              isDarkMode
                ? 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            }`}
          >
            {isSubmitting ? 'Enviando...' : 'Enviar Solicitud'}
          </button>
        </form>
        {submitMessage && (
          <div className={`mt-4 text-sm ${submitMessage.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
            {submitMessage}
          </div>
        )}
      </div>
    </div>
  );
}