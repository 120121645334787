'use client'

import React, { useState } from 'react'
import { useTheme } from '../contexts/ThemeContext'
import Header from './Header'
import Navigation from './Navigation'
import Content from './Content'

export default function Layout({ userData, onLogout, pb }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [activeContent, setActiveContent] = useState('home')
  const { isDarkMode } = useTheme()

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen)

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <Navigation
        userData={userData}
        onLogout={onLogout}
        setActiveContent={setActiveContent}
        activeContent={activeContent}
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <div className="ml-0 lg:ml-64 transition-all duration-300">
        <Header 
          toggleSidebar={toggleSidebar} 
          setActiveContent={setActiveContent} 
          isSidebarOpen={isSidebarOpen}
        />
        <main className="p-4">
          <Content
            activeContent={activeContent}
            userData={userData}
            pb={pb}
          />
        </main>
      </div>
    </div>
  )
}