import React, { useState, useEffect } from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, Rectangle } from 'recharts'
import { Calendar, Clock } from 'lucide-react'
import PocketBase from 'pocketbase'

const pb = new PocketBase(process.env.NEXT_PUBLIC_POCKETBASE_URL)
pb.autoCancellation(false)
const COLORS = [
  '#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088FE', '#00C49F', '#FFBB28', '#FF8042',
  '#a4de6c', '#d0ed57', '#83a6ed', '#8dd1e1', '#82ca9d', '#e6ab02', '#1f77b4', '#d62728'
]

async function fetchUserSchedule(userId) {
  try {
    const records = await pb.collection('calendarioRolUbicacion').getList(1, 100, {
      filter: `usuarioAsignar = "${userId}"`,
      sort: 'fecha_inicio',
      expand: 'division',
      $autoCancel: false
    })
    console.log('Fetched records:', records.items)
    return records.items
  } catch (error) {
    console.error('Error fetching user schedule:', error)
    throw error
  }
}

const CustomBar = (props) => {
  const { x, y, width, height, fill } = props
  return <Rectangle x={x} y={y} width={width} height={height} fill={fill} radius={[7, 7, 0, 0]} />
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white dark:bg-gray-800 p-2 border border-gray-200 dark:border-gray-700 rounded shadow">
        <p className="font-bold">{label}</p>
        <p>{`División: ${payload[0].payload.division}`}</p>
        <p>{`Horario: ${payload[0].payload.horario}`}</p>
      </div>
    )
  }
  return null
}

export default function RolAnual({ userId }) {
  const [scheduleData, setScheduleData] = useState([])
  const [currentMonthData, setCurrentMonthData] = useState([])
  const [divisionColors, setDivisionColors] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    setError(null)

    fetchUserSchedule(userId)
      .then(data => {
        console.log('Received data:', data)
        if (data.length === 0) {
          setError('No se encontraron datos para este usuario.')
          return
        }
        setScheduleData(data)

        const now = new Date()
        const currentMonthData = data.filter(item => {
          const startDate = new Date(item.fecha_inicio)
          const endDate = new Date(item.fecha_final)
          return (
            (startDate.getMonth() === now.getMonth() && startDate.getFullYear() === now.getFullYear()) ||
            (endDate.getMonth() === now.getMonth() && endDate.getFullYear() === now.getFullYear())
          )
        })
        setCurrentMonthData(currentMonthData)

        const divisions = [...new Set(data.map(item => item.expand?.division?.name || 'Sin división'))]
        const colors = {}
        divisions.forEach((division, index) => {
          colors[division] = COLORS[index % COLORS.length]
        })
        setDivisionColors(colors)
      })
      .catch(err => {
        console.error('Error in fetchUserSchedule:', err)
        setError('Error al cargar los datos. Por favor, intente de nuevo.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [userId])

  const chartData = scheduleData.flatMap(item => {
    const start = new Date(item.fecha_inicio)
    const end = new Date(item.fecha_final)
    const days = []
    for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
      days.push({
        date: new Date(d).toISOString().split('T')[0],
        division: item.expand?.division?.name || 'Sin división',
        horario: item.horario_laboral,
      })
    }
    return days
  })

  const divisionCounts = chartData.reduce((acc, item) => {
    acc[item.division] = (acc[item.division] || 0) + 1
    return acc
  }, {})

  const pieChartData = Object.entries(divisionCounts).map(([name, value]) => ({ name, value }))

  if (isLoading) {
    return <div className="p-4">Cargando datos...</div>
  }

  if (error) {
    return <div className="p-4 text-red-500">{error}</div>
  }

  if (scheduleData.length === 0) {
    return <div className="p-4">No hay datos disponibles.</div>
  }

  return (
    <div className="p-4 space-y-6">
      <h2 className="text-3xl font-bold mb-4">Rol Anual</h2>
      
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-4">
        <h3 className="text-xl font-semibold mb-4">Asignaciones del Mes Actual</h3>
        {currentMonthData.length > 0 ? (
          <ul className="space-y-2">
            {currentMonthData.map((item, index) => (
              <li key={index} className="flex items-center space-x-2 p-3 hover:bg-gray-100 dark:hover:bg-gray-700 rounded transition duration-150 ease-in-out">
                <Calendar className="text-blue-500 dark:text-blue-400" />
                <span className="font-medium">{new Date(item.fecha_inicio).toLocaleDateString()} - {new Date(item.fecha_final).toLocaleDateString()}</span>
                <Clock className="text-green-500 dark:text-green-400 ml-4" />
                <span className="font-medium">{item.horario_laboral}</span>
                <span className="font-bold ml-4 px-2 py-1 rounded" style={{ backgroundColor: divisionColors[item.expand?.division?.name || 'Sin división'], color: '#ffffff' }}>
                  {item.expand?.division?.name || 'Sin división'}
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <p>No hay asignaciones para el mes actual.</p>
        )}
      </div>

      <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-4">
        <h3 className="text-xl font-semibold mb-4">Lista de Asignaciones</h3>
        <ul className="space-y-2">
          {scheduleData.map((item, index) => (
            <li key={index} className="flex items-center space-x-2 p-3 hover:bg-gray-100 dark:hover:bg-gray-700 rounded transition duration-150 ease-in-out">
              <Calendar className="text-blue-500 dark:text-blue-400" />
              <span className="font-medium">{new Date(item.fecha_inicio).toLocaleDateString()} - {new Date(item.fecha_final).toLocaleDateString()}</span>
              <Clock className="text-green-500 dark:text-green-400 ml-4" />
              <span className="font-medium">{item.horario_laboral}</span>
              <span className="font-bold ml-4 px-2 py-1 rounded" style={{ backgroundColor: divisionColors[item.expand?.division?.name || 'Sin división'], color: '#ffffff' }}>
                {item.expand?.division?.name || 'Sin división'}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-4">
          <h3 className="text-xl font-semibold mb-4">Distribución por División</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={pieChartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={divisionColors[entry.name]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-4">
          <h3 className="text-xl font-semibold mb-4">Línea de Tiempo Detallada</h3>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={chartData} barSize={20}>
              <XAxis
                dataKey="date"
                scale="time"
                type="category"
                tick={{ fontSize: 12 }}
                tickFormatter={(tick) => new Date(tick).toLocaleDateString()}
              />
              <YAxis dataKey="division" type="category" />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="division" fill="#8884d8" shape={<CustomBar />}>
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={divisionColors[entry.division]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}