'use client'

import React from 'react'
import Capacitaciones from './Capacitaciones'
import PermissionRequest from './PermissionRequest'
import GiftGuardShift from './GiftGuardShift'
import ApproverInbox from './ApproverInbox/ApproverInbox'
import CambioGuardias from './cambioGuardias'
import Evaluaciones from './Evaluaciones'
import Listadeoferta from './listadeoferta'
import AgregarTicket from './AgregarTicket'
import PerfilUsuario from './perfilUsuario'
import Soco from './socos'
import RolAnual from './RolAnual'
import Home from './Home'
import Configuracion from './Configuracion'

export default function Content({ activeContent, userData, pb }) {
  const renderContent = () => {
    switch (activeContent) {
      case 'home':
        return <Home userData={userData} pb={pb} />
      case 'profile':
        return <PerfilUsuario userData={userData} />
      case 'capacitaciones':
        return <Capacitaciones userData={userData} />
      case 'solicitar-permiso':
        return <PermissionRequest userData={userData} />
      case 'regalar-guardia':
        return <GiftGuardShift userData={userData} />
      case 'bandeja-entrada':
        return <ApproverInbox userData={userData} />
      case 'cambio-guardias':
        return <CambioGuardias userData={userData} />
      case 'rol-anual':
        return <RolAnual userData={userData} />
      case 'evaluaciones':
        return <Evaluaciones userData={userData} />
      case 'listadeoferta':
        return <Listadeoferta userData={userData} />
      case 'crear-ticket':
        return <AgregarTicket />
      case 'socos':
        return <Soco/>
      case 'configuracion':
        return <Configuracion userData={userData} updateUserData={() => {}} />
      default:
        return <Home userData={userData} pb={pb} />
    }
  }

  return (
    <div className="p-4 w-full min-h-screen">
      {renderContent()}
    </div>
  )
}