import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PocketBase from 'pocketbase';
import { useTheme } from '../contexts/ThemeContext';
import { Calendar, User, UserCheck, Loader, Building, Bell } from 'lucide-react';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);
pb.autoCancellation(false);

export default function PermissionRequest({ userData }) {
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const { isDarkMode } = useTheme();
  const [weekendDates, setWeekendDates] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        await pb.admins.authWithPassword(
          process.env.REACT_APP_ADMIN_EMAIL,
          process.env.REACT_APP_ADMIN_PASSWORD
        );
        const usersResponse = await pb.collection('users').getList(1, 1000, { sort: 'name', $autoCancel: false });
        const approversResponse = await pb.collection('users').getList(1, 1000, {
          filter: 'role = "jefatura" || is_approver = true',
          sort: 'name',
          $autoCancel: false
        });
        const divisionsResponse = await pb.collection('divisions').getList(1, 1000, { sort: 'name', $autoCancel: false });
        if (isMounted) {
          setUsers(usersResponse.items);
          setFilteredUsers(usersResponse.items);
          setApprovers(approversResponse.items);
          const filteredDivisions = divisionsResponse.items.filter(division =>
            !['gestion de calidad', 'laboratorio', 'hormonas'].includes(division.name.toLowerCase())
          );
          setDivisions(filteredDivisions);
          const uniqueLocations = [...new Set(usersResponse.items.map(user => user.ubicacion))];
          setLocations(uniqueLocations);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        if (isMounted) {
          setMessage('Error al cargar los datos. Por favor, intente de nuevo más tarde.');
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const generateWeekendDates = () => {
      const dates = [];
      const today = new Date();
      const threeMonthsLater = new Date(today.getFullYear(), today.getMonth() + 3, today.getDate());
      for (let d = new Date(today); d <= threeMonthsLater; d.setDate(d.getDate() + 1)) {
        if (d.getDay() === 0 || d.getDay() === 6) {
          dates.push(new Date(d));
        }
      }
      return dates;
    };
    const weekends = generateWeekendDates();
    setWeekendDates(weekends);
    if (weekends.length > 0) {
      setValue('fecha_de_permiso', formatDate(weekends[0]));
    }
  }, [setValue]);

  const formatDate = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      console.error('Invalid date object:', date);
      return '';
    }
    return date.toISOString().split('T')[0];
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setMessage('');
    try {
      await pb.admins.authWithPassword(
        process.env.REACT_APP_ADMIN_EMAIL,
        process.env.REACT_APP_ADMIN_PASSWORD
      );
      const permissionData = {
        usuario_de_peticion: userData.id,
        usuario_de_receptor: data.usuario_de_receptor,
        usuario_de_aprobador: data.usuario_de_aprobador,
        fecha_de_permiso: data.fecha_de_permiso,
        division: data.division,
        status: 'Pendiente',
      };
      const nuevaGuardia = await pb.collection('permisos_guardia').create(permissionData);
      console.log('Nueva guardia creada:', nuevaGuardia);

      const fechaPermiso = new Date(nuevaGuardia.fecha_de_permiso);
      
      try {
        await sendPushNotification(
          data.usuario_de_receptor,
          'Nueva Solicitud de Guardia',
          `Se ha creado una nueva solicitud de guardia para ${formatDate(fechaPermiso)}`,
          {
            url: 'https://portalpersonal.synthiq.dev/gestion-guardias',
            peticionario: userData.name,
            fecha: formatDate(fechaPermiso),
            division: divisions.find(d => d.id === data.division)?.name
          }
        );
        setMessage('Solicitud de permiso de guardia enviada con éxito y notificación enviada.');
      } catch (notificationError) {
        console.error('Error al enviar la notificación:', notificationError);
        setMessage('Solicitud creada con éxito.');
      }
      
      reset();
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
      setMessage(`Error al enviar la solicitud: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const filterUsersByLocation = (location) => {
    setSelectedLocation(location);
    if (location === '') {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(users.filter(user => user.ubicacion === location));
    }
  };

  const handleSubscribe = async () => {
    try {
      await subscribeUserToPush(userData.id);
      setIsSubscribed(true);
      setMessage('Notificaciones push activadas con éxito');
    } catch (error) {
      console.error('Error al suscribirse a notificaciones push:', error);
      setMessage('Error al activar notificaciones push. Por favor, inténtelo de nuevo.');
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin h-12 w-12 text-blue-500" />
      </div>
    );
  }

  return (
    <div className={`w-full h-26 pb-28 pl-10 pt-10 pr-10 ${isDarkMode ? 'bg-grey-800 text-white' : 'bg-white text-gray-800'} shadow-lg rounded-lg transition-all duration-300`}>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Solicitud de Permiso de Guardia</h2>
        <button
          onClick={handleSubscribe}
          disabled={isSubscribed}
          className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} ${isSubscribed ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <Bell className="w-5 h-5" />
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <FormField id="division" label="División de la guardia" icon={<Building className="h-5 w-5" />} error={errors.division} isDarkMode={isDarkMode}>
          <select id="division" {...register("division", { required: "Este campo es requerido" })} className={inputClassName(isDarkMode)}>
            <option value="">Seleccione una división</option>
            {divisions.map(division => (
              <option key={division.id} value={division.id}>{division.name}</option>
            ))}
          </select>
        </FormField>
        <FormField id="fecha_de_permiso" label="Fecha de permiso" icon={<Calendar className="h-5 w-5" />} error={errors.fecha_de_permiso} isDarkMode={isDarkMode}>
          <select id="fecha_de_permiso" {...register("fecha_de_permiso", { required: "Este campo es requerido" })} className={inputClassName(isDarkMode)}>
            {weekendDates.map((date, index) => (
              <option key={index} value={formatDate(date)}>
                {date instanceof Date && !isNaN(date)
                  ? date.toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
                  : 'Fecha inválida'}
              </option>
            ))}
          </select>
        </FormField>
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Filtrar por ubicación:</h3>
          <div className="flex flex-wrap gap-2">
            <button
              type="button"
              onClick={() => filterUsersByLocation('')}
              className={`px-3 py-1 rounded-full text-sm ${
                selectedLocation === '' ? 'bg-blue-500 text-white' : isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-800'
              }`}
            >
              Todos
            </button>
            {locations.map(location => (
              <button
                key={location}
                type="button"
                onClick={() => filterUsersByLocation(location)}
                className={`px-3 py-1 rounded-full text-sm ${
                  selectedLocation === location ? 'bg-blue-500 text-white' : isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-800'
                }`}
              >
                {location}
              </button>
            ))}
          </div>
        </div>
        <FormField id="usuario_de_receptor" label="Usuario a recibir la guardia" icon={<User className="h-5 w-5" />} error={errors.usuario_de_receptor} isDarkMode={isDarkMode}>
          <select id="usuario_de_receptor" {...register("usuario_de_receptor", { required: "Este campo es requerido" })} className={inputClassName(isDarkMode)}>
            <option value="">Seleccione un usuario</option>
            {filteredUsers.map(user => (
              <option key={user.id} value={user.id}>{user.name} - {user.ubicacion}</option>
            ))}
          </select>
        </FormField>
        <FormField id="usuario_de_aprobador" label="Jefatura responsable" icon={<UserCheck className="h-5 w-5" />} error={errors.usuario_de_aprobador} isDarkMode={isDarkMode}>
          <select id="usuario_de_aprobador" {...register("usuario_de_aprobador", { required: "Este campo es requerido" })} className={inputClassName(isDarkMode)}>
            <option value="">Seleccione una jefatura</option>
            {approvers.map(approver => (
              <option key={approver.id} value={approver.id}>
                {approver.name} {approver.role ? ` - ${approver.role}` : ''} {approver.is_approver ? ' - Aprobador' : ''}
              </option>
            ))}
          </select>
        </FormField>
        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
            isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-600 hover:bg-blue-700'
          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out`}
        >
          {isSubmitting ? 'Enviando...' : 'Enviar Solicitud'}
        </button>
      </form>
      {message && (
        <div className={`mt-4 p-3 rounded-md ${
          message.includes('Error') ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'
        }`}>
          {message}
        </div>
      )}
    </div>
  );
}

function FormField({ id, label, icon, error, children, isDarkMode }) {
  return (
    <div>
      <label htmlFor={id} className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          {React.cloneElement(icon, { className: `${icon.props.className} ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}` })}
        </div>
        {children}
      </div>
      {error && <span className="text-red-500 text-xs mt-1">{error.message}</span>}
    </div>
  );
}

function inputClassName(isDarkMode) {
  return `block w-full pl-10 pr-3 py-2 sm:text-sm border-gray-300 rounded-md ${
    isDarkMode ? 'bg-gray-700 text-white focus:ring-blue-500 focus:border-blue-500' : 'bg-white text-gray-900 focus:ring-blue-500 focus:border-blue-500'
  }`;
}

async function subscribeUserToPush(userId) {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    try {
      const registration = await navigator.serviceWorker.register('/service-worker.js');
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY
      });
      
      const appVersion = process.env.REACT_APP_VERSION || '1.0.0';
      
      await pb.collection('push_subscriptions').create({
        user: userId,
        subscription: JSON.stringify({
          ...subscription,
          appVersion: appVersion
        })
      });
      
      return subscription;
    } catch (error) {
      console.error('Error al suscribir al usuario a notificaciones push:', error);
      throw error;
    }
  } else {
    throw new Error('Este navegador no soporta notificaciones push');
  }
}

async function sendPushNotification(userId, title, body, data = {}) {
  try {
    const subscriptions = await pb.collection('push_subscriptions').getList(1, 1, {
      filter: `user = "${userId}"`
    });
    
    if (subscriptions.items.length === 0) {
      console.warn('El usuario no está suscrito a notificaciones push');
      throw new Error('Usuario no suscrito a notificaciones push');
    }
    
    const subscription = JSON.parse(subscriptions.items[0].subscription);
    
    const appVersion = subscription.appVersion || '1.0.0';
    const minSupportedVersion = '1.2.0'; // Ajusta esto según sea necesario
    
    if (compareVersions(appVersion, minSupportedVersion) < 0) {
      console.warn(`Versión de la aplicación ${appVersion} no soportada para notificaciones push`);
      throw new Error('Versión de la aplicación no soportada para notificaciones push');
    }
    
    const response = await pb.send('/api/send-push', {
      subscription: subscription,
      title: title,
      body: body,
      data: data,
      appVersion: appVersion
    });
    
    if (!response.ok) {
      throw new Error(`Error en la respuesta del servidor: ${response.status} ${response.statusText}`);
    }
    
    console.log('Notificación push enviada con éxito');
  } catch (error) {
    console.error('Error al enviar notificación push:', error);
    throw error;
  }
}

function compareVersions(v1, v2) {
  const parts1 = v1.split('.').map(Number);
  const parts2 = v2.split('.').map(Number);
  
  for (let i = 0; i < 3; i++) {
    if (parts1[i] > parts2[i]) return 1;
    if (parts1[i] < parts2[i]) return -1;
  }
  
  return 0;
}