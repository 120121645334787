import React from 'react';
import Lottie from 'lottie-react';

export default function LoadingAnimation() {
  const [animationData, setAnimationData] = React.useState(null);

  React.useEffect(() => {
    fetch('/animations/loading.json')
      .then(response => response.json())
      .then(data => setAnimationData(data))
      .catch(error => console.error('Error loading animation:', error));
  }, []);

  if (!animationData) {
    return <div>Loading animation...</div>;
  }

  return (
    <div className="flex justify-center items-center h-full">
      <Lottie 
        animationData={animationData}
        loop={true}
        style={{ width: 200, height: 200 }}
      />
    </div>
  );
}