import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import PocketBase from 'pocketbase'
import { useUser } from '../../UserContext'
import { useTheme } from '../../contexts/ThemeContext'
import { Sun, Moon, Check, X } from 'lucide-react'

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL)
pb.autoCancellation(false)

function StatusViews() {
  const { user } = useUser()
  const { isDarkMode, toggleTheme } = useTheme()
  const [myRequests, setMyRequests] = useState([])
  const [approvalRequests, setApprovalRequests] = useState([])
  const [activeSection, setActiveSection] = useState('myRequests')
  const [activeTab, setActiveTab] = useState('all')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (user) {
      fetchRequests()
    }
  }, [user])

  const fetchRequests = async () => {
    setIsLoading(true)
    try {
      const [myRecords, approvalRecords] = await Promise.all([
        pb.collection('permission_requests').getList(1, 100, {
          filter: `requester = "${user.id}"`,
          sort: '-created',
          expand: 'approver',
        }),
        pb.collection('permission_requests').getList(1, 100, {
          filter: `approver = "${user.id}"`,
          sort: '-created',
          expand: 'requester',
        })
      ])
      setMyRequests(myRecords.items)
      setApprovalRequests(approvalRecords.items)
    } catch (error) {
      console.error('Error fetching requests:', error)
      alert("No se pudieron cargar las solicitudes.")
    } finally {
      setIsLoading(false)
    }
  }

  const updateRequestStatus = async (requestId, newStatus) => {
    try {
      await pb.collection('permission_requests').update(requestId, { status: newStatus })
      fetchRequests() // Refresh the requests after updating
      alert(`La solicitud ha sido ${newStatus.toLowerCase()}.`)
    } catch (error) {
      console.error('Error updating request status:', error)
      alert("No se pudo actualizar el estado de la solicitud.")
    }
  }

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd MMM yy', { locale: es })
  }

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'aprobado':
        return isDarkMode
          ? 'bg-green-800 text-green-100 border-green-700'
          : 'bg-green-100 text-green-800 border-green-300'
      case 'rechazado':
        return isDarkMode
          ? 'bg-red-800 text-red-100 border-red-700'
          : 'bg-red-100 text-red-800 border-red-300'
      default:
        return isDarkMode
          ? 'bg-yellow-800 text-yellow-100 border-yellow-700'
          : 'bg-yellow-100 text-yellow-800 border-yellow-300'
    }
  }

  const filteredRequests = (activeSection === 'myRequests' ? myRequests : approvalRequests)
    .filter(request => activeTab === 'all' || request.status.toLowerCase() === activeTab)

  const renderRequestCard = (request) => (
    <div key={request.id} className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 p-4 mb-4`}>
      <div className="flex justify-between items-center mb-2">
        <span className={`px-2 py-1 rounded-full text-xs font-semibold ${getStatusColor(request.status)} border`}>
          {request.status}
        </span>
        <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
          {formatDate(request.start_date)} - {formatDate(request.end_date)}
        </span>
      </div>
      <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-800'} mb-2 line-clamp-2`}>{request.reason}</p>
      <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} mb-2`}>
        {activeSection === 'myRequests'
          ? `Aprobador: ${request.expand?.approver?.name || 'No asignado'}`
          : `Solicitante: ${request.expand?.requester?.name || 'No disponible'}`}
      </p>
      {activeSection === 'approvals' && request.status.toLowerCase() === 'pendiente' && (
        <div className="flex justify-end space-x-2">
          <button
            onClick={() => updateRequestStatus(request.id, 'Aprobado')}
            className="px-3 py-1 bg-green-500 text-white rounded-md text-sm flex items-center"
          >
            <Check className="w-4 h-4 mr-1" /> Aprobar
          </button>
          <button
            onClick={() => updateRequestStatus(request.id, 'Rechazado')}
            className="px-3 py-1 bg-red-500 text-white rounded-md text-sm flex items-center"
          >
            <X className="w-4 h-4 mr-1" /> Rechazar
          </button>
        </div>
      )}
    </div>
  )

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-800'} p-4`}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold">Permisos sin Goce</h1>
      </div>
      <div className="flex mb-4 overflow-x-auto pb-2">
        <button
          className={`mr-2 py-2 px-4 rounded-full text-sm font-medium focus:outline-none transition-colors duration-200 ${
            activeSection === 'myRequests'
              ? 'bg-blue-600 text-white'
              : isDarkMode
              ? 'bg-gray-700 text-gray-300'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setActiveSection('myRequests')}
        >
          Mis Solicitudes
        </button>
        <button
          className={`py-2 px-4 rounded-full text-sm font-medium focus:outline-none transition-colors duration-200 ${
            activeSection === 'approvals'
              ? 'bg-blue-600 text-white'
              : isDarkMode
              ? 'bg-gray-700 text-gray-300'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setActiveSection('approvals')}
        >
          Por Aprobar
        </button>
      </div>
      <div className="flex mb-4 overflow-x-auto pb-2">
        {['all', 'pendiente', 'aprobado', 'rechazado'].map((tab) => (
          <button
            key={tab}
            className={`mr-2 py-1 px-3 rounded-full text-xs font-medium focus:outline-none transition-colors duration-200 ${
              activeTab === tab
                ? 'bg-gray-800 text-white'
                : isDarkMode
                ? 'bg-gray-700 text-gray-300'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab === 'all' ? 'Todas' : tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-32">
          <div className={`animate-spin rounded-full h-8 w-8 border-b-2 ${isDarkMode ? 'border-blue-400' : 'border-blue-600'}`}></div>
        </div>
      ) : filteredRequests.length === 0 ? (
        <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'} text-center py-8`}>No hay solicitudes para mostrar.</p>
      ) : (
        <div className="space-y-4">
          {filteredRequests.map(renderRequestCard)}
        </div>
      )}
    </div>
  )
}

export default StatusViews