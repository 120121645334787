import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import PocketBase from 'pocketbase'
import { useUser } from '../../UserContext'
import { useTheme } from '../../contexts/ThemeContext'
import { Sun, Moon } from 'lucide-react'

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL)
pb.autoCancellation(false)

function GestionGuardias() {
  const { user } = useUser()
  const { isDarkMode, toggleTheme } = useTheme()
  const [misPeticiones, setMisPeticiones] = useState([])
  const [guardiasRecibidas, setGuardiasRecibidas] = useState([])
  const [guardiasParaAprobar, setGuardiasParaAprobar] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('misPeticiones')

  useEffect(() => {
    if (user) {
      fetchGuardias()
    }
  }, [user])

  const fetchGuardias = async () => {
    setIsLoading(true)
    try {
      const [peticiones, recibidas, paraAprobar] = await Promise.all([
        pb.collection('permisos_guardia').getList(1, 100, {
          filter: `usuario_de_peticion = "${user.id}"`,
          sort: '-created',
          expand: 'division,usuario_de_receptor,usuario_de_aprobador',
        }),
        pb.collection('permisos_guardia').getList(1, 100, {
          filter: `usuario_de_receptor = "${user.id}"`,
          sort: '-created',
          expand: 'division,usuario_de_peticion',
        }),
        pb.collection('permisos_guardia').getList(1, 100, {
          filter: `usuario_de_aprobador = "${user.id}" && reseptorDeacuerdo = "deacuerdo"`,
          sort: '-created',
          expand: 'division,usuario_de_peticion,usuario_de_receptor',
        }),
      ])
      setMisPeticiones(peticiones.items)
      setGuardiasRecibidas(recibidas.items)
      setGuardiasParaAprobar(paraAprobar.items)
    } catch (error) {
      console.error('Error fetching guardias:', error)
      alert("No se pudieron cargar las guardias.")
    } finally {
      setIsLoading(false)
    }
  }

  const updateReseptorDeacuerdo = async (guardiaId, newValue) => {
    try {
      await pb.collection('permisos_guardia').update(guardiaId, {
        reseptorDeacuerdo: newValue
      })
      await fetchGuardias()
      alert(`Has marcado como ${newValue === 'deacuerdo' ? 'de acuerdo' : 'en desacuerdo'} con la guardia.`)
    } catch (error) {
      console.error('Error updating guardia:', error)
      alert("No se pudo actualizar el estado de la guardia.")
    }
  }

  const updateStatus = async (guardiaId, newStatus) => {
    try {
      await pb.collection('permisos_guardia').update(guardiaId, {
        status: newStatus
      })
      await fetchGuardias()
      alert(`El estado de la guardia ha sido actualizado a ${newStatus}.`)
    } catch (error) {
      console.error('Error updating guardia status:', error)
      alert("No se pudo actualizar el estado de la guardia.")
    }
  }

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd MMM yy', { locale: es })
  }

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'aprobado':
        return isDarkMode
          ? 'bg-green-800 text-green-100 border-green-700'
          : 'bg-green-100 text-green-800 border-green-300'
      case 'rechazado':
        return isDarkMode
          ? 'bg-red-800 text-red-100 border-red-700'
          : 'bg-red-100 text-red-800 border-red-300'
      default:
        return isDarkMode
          ? 'bg-yellow-800 text-yellow-100 border-yellow-700'
          : 'bg-yellow-100 text-yellow-800 border-yellow-300'
    }
  }

  const renderGuardiaCard = (guardia, type) => (
    <div key={guardia.id} className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-sm border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} p-4 mb-4`}>
      <div className="flex justify-between items-center mb-2">
        <span className={`px-2 py-1 rounded-full text-xs font-semibold ${getStatusColor(guardia.status)} border`}>
          {guardia.status}
        </span>
        <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
          Fecha: {formatDate(guardia.fecha_de_permiso)}
        </span>
      </div>
      <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-800'} mb-1`}>División: {guardia.expand?.division?.name || 'No especificada'}</p>
      {type === 'misPeticiones' && (
        <>
          <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} mb-1`}>Receptor: {guardia.expand?.usuario_de_receptor?.name || 'No asignado'}</p>
          <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>Aprobador: {guardia.expand?.usuario_de_aprobador?.name || 'No asignado'}</p>
        </>
      )}
      {type === 'guardiasRecibidas' && (
        <>
          <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} mb-2`}>Solicitante: {guardia.expand?.usuario_de_peticion?.name || 'No especificado'}</p>
          <div className="flex flex-wrap gap-2 mt-2">
            <button
              onClick={() => updateReseptorDeacuerdo(guardia.id, 'deacuerdo')}
              className={`px-3 py-1 rounded text-xs font-medium ${
                guardia.reseptorDeacuerdo === 'deacuerdo'
                  ? 'bg-green-500 text-white'
                  : isDarkMode
                  ? 'bg-gray-700 text-gray-200'
                  : 'bg-gray-200 text-gray-800'
              }`}
            >
              De acuerdo
            </button>
            <button
              onClick={() => updateReseptorDeacuerdo(guardia.id, 'desacuerdo')}
              className={`px-3 py-1 rounded text-xs font-medium ${
                guardia.reseptorDeacuerdo === 'desacuerdo'
                  ? 'bg-red-500 text-white'
                  : isDarkMode
                  ? 'bg-gray-700 text-gray-200'
                  : 'bg-gray-200 text-gray-800'
              }`}
            >
              En desacuerdo
            </button>
          </div>
        </>
      )}
      {type === 'guardiasParaAprobar' && (
        <>
          <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} mb-1`}>Solicitante: {guardia.expand?.usuario_de_peticion?.name || 'No especificado'}</p>
          <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} mb-2`}>Receptor: {guardia.expand?.usuario_de_receptor?.name || 'No asignado'}</p>
          <div className="flex flex-wrap gap-2 mt-2">
            <button
              onClick={() => updateStatus(guardia.id, 'Aprobado')}
              className={`px-3 py-1 rounded text-xs font-medium ${
                guardia.status === 'Aprobado'
                  ? 'bg-green-500 text-white'
                  : isDarkMode
                  ? 'bg-gray-700 text-gray-200'
                  : 'bg-gray-200 text-gray-800'
              }`}
            >
              Aprobar
            </button>
            <button
              onClick={() => updateStatus(guardia.id, 'Rechazado')}
              className={`px-3 py-1 rounded text-xs font-medium ${
                guardia.status === 'Rechazado'
                  ? 'bg-red-500 text-white'
                  : isDarkMode
                  ? 'bg-gray-700 text-gray-200'
                  : 'bg-gray-200 text-gray-800'
              }`}
            >
              Rechazar
            </button>
          </div>
        </>
      )}
    </div>
  )

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-800'}`}>
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-bold">Entrega de Guardias Regaladas</h1>
        </div>
        <div className="flex mb-4 overflow-x-auto pb-2">
          <button
            className={`mr-2 py-2 px-4 rounded-full text-sm font-medium focus:outline-none transition-colors duration-200 ${
              activeTab === 'misPeticiones'
                ? 'bg-blue-600 text-white'
                : isDarkMode
                ? 'bg-gray-700 text-gray-300'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setActiveTab('misPeticiones')}
          >
            Mis Peticiones
          </button>
          <button
            className={`mr-2 py-2 px-4 rounded-full text-sm font-medium focus:outline-none transition-colors duration-200 ${
              activeTab === 'guardiasRecibidas'
                ? 'bg-blue-600 text-white'
                : isDarkMode
                ? 'bg-gray-700 text-gray-300'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setActiveTab('guardiasRecibidas')}
          >
            Guardias Recibidas
          </button>
          <button
            className={`py-2 px-4 rounded-full text-sm font-medium focus:outline-none transition-colors duration-200 ${
              activeTab === 'guardiasParaAprobar'
                ? 'bg-blue-600 text-white'
                : isDarkMode
                ? 'bg-gray-700 text-gray-300'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setActiveTab('guardiasParaAprobar')}
          >
            Para Aprobar
          </button>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center h-32">
            <div className={`animate-spin rounded-full h-8 w-8 border-b-2 ${isDarkMode ? 'border-blue-400' : 'border-blue-600'}`}></div>
          </div>
        ) : (
          <div className="space-y-4">
            {activeTab === 'misPeticiones' && misPeticiones.map(guardia => renderGuardiaCard(guardia, 'misPeticiones'))}
            {activeTab === 'guardiasRecibidas' && guardiasRecibidas.map(guardia => renderGuardiaCard(guardia, 'guardiasRecibidas'))}
            {activeTab === 'guardiasParaAprobar' && guardiasParaAprobar.map(guardia => renderGuardiaCard(guardia, 'guardiasParaAprobar'))}
          </div>
        )}
      </div>
    </div>
  )
}

export default GestionGuardias