import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import PocketBase from 'pocketbase';
import { useTheme } from '../contexts/ThemeContext';
import { Search, Moon, Sun, X, ChevronDown, ChevronUp } from 'lucide-react';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);
pb.autoCancellation(false);

function useResizeObserver(ref, callback) {
  useLayoutEffect(() => {
    if (!ref.current) return;
    
    const observer = new ResizeObserver(entries => {
      if (entries[0]) {
        callback(entries[0].contentRect);
      }
    });
    
    observer.observe(ref.current);
    
    return () => {
      observer.disconnect();
    };
  }, [ref, callback]);
}

export default function ListaDeOfertaView() {
  const [records, setRecords] = useState([]);
  const [groupedRecords, setGroupedRecords] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedDivisions, setExpandedDivisions] = useState({});
  const { isDarkMode, toggleTheme } = useTheme();
  const containerRef = useRef(null);

  useResizeObserver(containerRef, () => {
    // This callback will be called whenever the container is resized
    // You can add any additional logic here if needed
  });

  useEffect(() => {
    let isMounted = true;
    const fetchRecords = async () => {
      setIsLoading(true);
      try {
        await pb.admins.authWithPassword(
          process.env.REACT_APP_ADMIN_EMAIL,
          process.env.REACT_APP_ADMIN_PASSWORD
        );
        const resultList = await pb.collection('lista_de_oferta').getList(1, 1000, {
          sort: 'Division,Estudio',
        });
        if (isMounted) {
          if (resultList.items && resultList.items.length > 0) {
            setRecords(resultList.items);
            groupRecordsByDivision(resultList.items);
          } else {
            setError('No se encontraron registros. La colección puede estar vacía.');
          }
        }
      } catch (error) {
        if (isMounted) {
          setError(`Failed to fetch records: ${error.message}`);
        }
      }
      if (isMounted) {
        setIsLoading(false);
      }
    };
    fetchRecords();
    return () => {
      isMounted = false;
    };
  }, []);

  const groupRecordsByDivision = (records) => {
    const grouped = records.reduce((acc, record) => {
      if (!acc[record.Division]) {
        acc[record.Division] = [];
      }
      acc[record.Division].push(record);
      return acc;
    }, {});
    setGroupedRecords(grouped);
    setExpandedDivisions(Object.keys(grouped).reduce((acc, division) => {
      acc[division] = false; // Set all divisions to collapsed by default
      return acc;
    }, {}));
  };

  useEffect(() => {
    if (searchTerm) {
      const filteredRecords = records.filter(record =>
        record.Division.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.CODIGO_DIGITACION.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.Observaciones_Digitacion.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.Estudio.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.referido.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.Tipo_de_muestra.toLowerCase().includes(searchTerm.toLowerCase())
      );
      groupRecordsByDivision(filteredRecords);
    } else {
      groupRecordsByDivision(records);
    }
  }, [searchTerm, records]);

  const openModal = (record) => {
    setSelectedRecord(record);
  };

  const closeModal = () => {
    setSelectedRecord(null);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleDivision = (division) => {
    setExpandedDivisions(prev => ({
      ...prev,
      [division]: !prev[division]
    }));
  };

  if (isLoading) {
    return (
      <div className={`flex justify-center items-center h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`text-red-500 text-center mt-4 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
        {error}
      </div>
    );
  }

  return (
    <div ref={containerRef} className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
      <div className="container mx-auto p-4">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">Lista de Oferta HSJD</h1>
        </div>
        <div className="relative mb-6">
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearchChange}
            className={`w-full p-3 pl-10 rounded-lg ${
              isDarkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-black border-gray-300'
            } border focus:outline-none focus:ring-2 focus:ring-blue-500`}
          />
          <Search className="absolute left-3 top-3 text-gray-400" size={20} />
        </div>
        {Object.entries(groupedRecords).map(([division, records]) => (
          <div key={division} className="mb-6">
            <button
              onClick={() => toggleDivision(division)}
              className={`w-full text-left p-4 flex justify-between items-center rounded-lg ${
                isDarkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-gray-200 hover:bg-gray-300'
              }`}
            >
              <h2 className="text-xl font-semibold">{division}</h2>
              {expandedDivisions[division] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
            {expandedDivisions[division] && (
              <div className="mt-4 space-y-4">
                {records.map((record) => (
                  <div key={record.id} className="flex flex-col sm:flex-row items-stretch">
                    <div className={`w-full sm:w-40 flex flex-col items-center justify-center p-4 ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
                    } rounded-t-lg sm:rounded-l-lg sm:rounded-t-none`}>
                      <span className="text-2xl font-bold mb-2">{record.CODIGO_DIGITACION}</span>
                      <span className="text-sm text-center">{record.Tipo_de_muestra || 'N/A'}</span>
                    </div>
                    <div className={`flex-1 p-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-b-lg sm:rounded-r-lg sm:rounded-b-none`}>
                      <h3 className="text-lg font-semibold mb-2">{record.Estudio}</h3>
                      {record.referido && (
                        <p className="text-sm mb-2 px-2 py-1 rounded-full inline-block bg-blue-100 text-blue-800">
                          Referido a: {record.referido}
                        </p>
                      )}
                      <button
                        onClick={() => openModal(record)}
                        className={`w-full mt-2 px-4 py-2 rounded ${
                          isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
                        } text-white transition duration-300`}
                      >
                        Ver Detalles
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      {selectedRecord && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
          <div className={`max-w-3xl w-full p-6 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} max-h-[90vh] overflow-y-auto`}>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Detalles de {selectedRecord.Estudio}</h2>
              <button
                onClick={closeModal}
                className={`p-1 rounded-full ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}
              >
                <X size={24} />
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <DetailItem label="División" value={selectedRecord.Division} isDarkMode={isDarkMode} />
              <DetailItem label="CÓDIGO DIGITACIÓN" value={selectedRecord.CODIGO_DIGITACION} isDarkMode={isDarkMode} />
              <DetailItem label="Tipo de muestra" value={selectedRecord.Tipo_de_muestra} isDarkMode={isDarkMode} />
              <DetailItem label="Observaciones De Digitación" value={selectedRecord.Observaciones_Digitacion} isDarkMode={isDarkMode} />
              <DetailItem label="REFERIDO A" value={selectedRecord.referido} isDarkMode={isDarkMode} />
              <DetailItem label="Perfil" value={selectedRecord.Perfil} isDarkMode={isDarkMode} />
              <DetailItem label="Preparación del paciente" value={selectedRecord.Preparacion_del_paciente} isDarkMode={isDarkMode} />
              <DetailItem label="Indicaciones específicas al paciente" value={selectedRecord.indicaciones_especificas_al_paciente} isDarkMode={isDarkMode} />
              <DetailItem label="Uso Clínico" value={selectedRecord.Uso_Clinico} isDarkMode={isDarkMode} />
              <DetailItem label="Procedimiento de toma de muestra" value={selectedRecord.Procedimiento_de_toma_de_muestra} isDarkMode={isDarkMode} />
              <DetailItem label="Observaciones toma de muestra" value={selectedRecord.Observaciones_toma_de_muestra} isDarkMode={isDarkMode} />
              <DetailItem label="Estabilidad contenedor primario" value={selectedRecord.Estabilidad_contenedor_primario} isDarkMode={isDarkMode} />
              <DetailItem label="Estabilidad muestra alicuotada" value={selectedRecord.Estabilidad_muestra_alicuotada} isDarkMode={isDarkMode} />
              <DetailItem label="Nota Conservación" value={selectedRecord.Nota_Conservacion} isDarkMode={isDarkMode} />
              <DetailItem label="Cantidad Mínima" value={selectedRecord.Cantidad_Minima} isDarkMode={isDarkMode} />
              <DetailItem label="Tiempo Respuesta" value={selectedRecord.Tiempo_Respuesta} isDarkMode={isDarkMode} />
              <DetailItem label="Indicaciones otros Centros" value={selectedRecord.Indicaciones_otros_Centros} isDarkMode={isDarkMode} />
              <DetailItem label="Valores Referencia" value={selectedRecord.Valores_Referencia} isDarkMode={isDarkMode} />
              <DetailItem label="Valores Críticos" value={selectedRecord.Valores_Criticos_} isDarkMode={isDarkMode} />
              <DetailItem label="Restricción" value={selectedRecord.Restriccion} isDarkMode={isDarkMode} />
              <DetailItem label="Control Interno" value={selectedRecord.Control_Interno} isDarkMode={isDarkMode} />
              <DetailItem label="Control Externo" value={selectedRecord.ControL_externo} isDarkMode={isDarkMode} />
              <DetailItem label="Equipo Automatizado" value={selectedRecord.Equipo_Automatizado} isDarkMode={isDarkMode} />
              <DetailItem label="Teléfono información" value={selectedRecord.Telefono_informacion_} isDarkMode={isDarkMode} />
              <DetailItem label="Revisado por" value={selectedRecord.Revisado_por} isDarkMode={isDarkMode} />
              <DetailItem label="Fecha Revisión" value={selectedRecord.Fecha_Revision} isDarkMode={isDarkMode} />
              <DetailItem label="Código Edus" value={selectedRecord.Codigo_Edus} isDarkMode={isDarkMode} />
              <DetailItem label="EDUS" value={selectedRecord.edus} isDarkMode={isDarkMode} />
              <DetailItem label="Descripción Edus" value={selectedRecord.Descripcion_Edus} isDarkMode={isDarkMode} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function DetailItem({ label, value, isDarkMode }) {
  return (
    <div className={`p-3 rounded ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
      <strong className={`font-semibold ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>{label}:</strong>
      <p className={`mt-1 ${isDarkMode ? 'text-white' : 'text-black'}`}>{value || 'N/A'}</p>
    </div>
  );
}